import React from 'react'
import PropTypes from 'prop-types'

import { CONFIRM, INFO, ERROR } from '../../../domain/message/messageTypes'

const APPEARANCE_TO_COLOR = {
  [CONFIRM]: 'confirm',
  [ERROR]: 'error',
  [INFO]: 'info',
}

const Banner = ({ appearance, children }) =>
  <div className={`Banner status-message status-${APPEARANCE_TO_COLOR[appearance]}`} >
    {children}
  </div>

Banner.propTypes = {
  appearance: PropTypes.oneOf([ CONFIRM, ERROR, INFO ]),
  children: PropTypes.node.isRequired,
}

export default Banner
