import { RATING_INVALID, RATING_PROPERTY_UPDATE_SUCCESS } from '../actions/rating'
import { isValid, isValidReview } from '../domain/rating/ratingValidation'

export const initialState = {
  conditions: true,
  product: true,
  analysis: true,
  competence: true,
  clarity: true,
  accessibility: true,
  review: true,
  agreed: true,
}

export const reducer = (ratingValidation = initialState, action) => {
  switch (action.type) {
  case RATING_INVALID:
    return {
      conditions: isValid(action.rating.conditions),
      product: isValid(action.rating.product),
      analysis: isValid(action.rating.analysis),
      competence: isValid(action.rating.competence),
      clarity: isValid(action.rating.clarity),
      accessibility: isValid(action.rating.accessibility),
      review: isValidReview(action.rating.review),
      agreed: isValid(action.rating.agreed),
    }
  case RATING_PROPERTY_UPDATE_SUCCESS:
    return {
      ...ratingValidation,
      [action.property]: isValid(action.value) && (action.property === 'review' ? isValidReview(action.value) : true),
    }
  default:
    return ratingValidation
  }
}
