import React, { FC, ReactChild } from "react";

import StatusMessageStyles from "./StatusMessage.less";

type StatusType = "confirm" | "info" | "warning" | "error";

interface StatusMessageProps {
	headline: ReactChild;
	message: ReactChild;
	statusType: StatusType;
	isCompact?: boolean;
}

const StatusMessage: FC<StatusMessageProps> = ({
	headline,
	message,
	statusType,
	isCompact
}) => {
	const containerStyles = `${StatusMessageStyles["status-message"]} ${
		StatusMessageStyles[`status-${statusType}`]
	} ${isCompact ? StatusMessageStyles["status-message--short"] : ""}`;

	return (
		<div className={containerStyles}>
			<h3>{headline}</h3>
			<p>{message}</p>
		</div>
	);
};

StatusMessage.defaultProps = {
	isCompact: false
};

export { StatusMessage };
