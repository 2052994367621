import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../../thunks/rating'
import RatingPage from './RatingPage'

export default connect(
  state => ({
    rating: state.rating,
    ratingValidation: state.ratingValidation,
    user: state.user,
  }),
  dispatch => bindActionCreators(actions, dispatch),
)(RatingPage)

