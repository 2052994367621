import React, { FC } from "react";

import TooltipStyles from "./Tooltip.less";

type TooltipTargetProps = {
	/** Class property goes directly in TooltipTarget's root div. */
	className?: string;
} & JSX.IntrinsicElements["div"];

/**
 * TooltipTarget provides the target that can be hovered to show a tooltip.
 */
const TooltipTarget: FC<TooltipTargetProps> = ({
	children,
	className,
	...otherProps
}) => {
	const targetClassName = [
		"CosmaTooltipTarget",
		className,
		TooltipStyles["tooltip-target"],
	].filter(className => !!className).join(" ");

	return (
		<div className={targetClassName} {...otherProps}>
			{children}
		</div>
	);
};

export { TooltipTarget };
