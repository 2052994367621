import React from 'react'
import { FormattedMessage as Msg, FormattedNumber as Number } from 'react-intl'
import { RatingShape } from '../../domain/rating/RatingShape'
import { calculateTotalRating } from '../../domain/rating/calculateTotalRating'

const TotalRating = ({ rating }) =>
  <>
    <div className='TotalRatingText font-s font-bold align-left'>
      <Msg id='rating.total' defaultMessage='Total rating' />
      <span className='number font-bold padding-left-m'>
        <Number value={calculateTotalRating(rating)} minimumFractionDigits={1} />
      </span>
      &nbsp;
      <Msg id='rating.stars' defaultMessage='Stars' />
    </div>
    <div className='TotalRatingNumber font-xs align-left'>
      <Msg
        id='rating.pleaseRate'
        defaultMessage='Please rate all sections and enter a text'
        values={{ br: <br /> }}
      />
    </div>
  </>

TotalRating.propTypes = {
  rating: RatingShape.isRequired,
}

export default TotalRating
