import React from 'react'
import PropTypes from 'prop-types'

import Star, { EMPTY, FULL, HALF } from './Star'

const Rating = ({ value }) =>
  <span className='align-middle'>
    {[ ...Array(5) ].map((_, i) =>
      <Star key={i} appearance={value >= i + 1 ? FULL : value > i ? HALF : EMPTY} />,
    )}
  </span>

Rating.propTypes = {
  value: PropTypes.number,
}

export default Rating
