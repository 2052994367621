import React, { Component, RefObject, ReactChild } from "react";
import { addDays, addMonths, getDay, getDaysInMonth, isToday } from "date-fns";

import { Button } from "../Button/Button";
import { TextInput } from "../Inputs/TextInput/TextInput";

import DatePickerStyle from "./DatePicker.less";
import { Modal } from "../Modal/Modal";

type DatePickerProps = {
	id: string;
	headline?: string;
	subheadline?: string;
	initialDisplayedMonth?: Date;
	preselectedDate?: Date;
	preselectedEndDate?: Date;
	/** Labels can be either strings or JSX */
	label?: ReactChild;
	placeholder?: string;
	daterange?: boolean;
	locale?: "de-DE" | "en-US" | "en-GB";
	ariaLabelNextMonth?: string;
	ariaLabelPreviousMonth?: string;
	inputProps?: Omit<JSX.IntrinsicElements["input"], "ref">;
	inputRef?: RefObject<HTMLInputElement>;
	cancelButtonClassName?: string;
	okButtonClassName?: string;
	onChange?: (selectedStartDate?: Date, selectedEndDate?: Date) => unknown;
	errorMessage?: string;
	valid?: boolean;
	success?: boolean;

};

type DatePickerState = {
	displayedMonth: Date;
	isDatePickerModalVisible: boolean;
	selectedStartDate: Date;
	selectedEndDate: Date;
	today: Date;
	wrongDate: boolean;
	selectingStartDate: boolean;
};

class DatePicker extends Component<DatePickerProps, DatePickerState> {
	private selectedStartDateUponOpeningModal: Date;
	private selectedEndDateUponOpeningModal: Date;
	private dateInputField: RefObject<HTMLInputElement>;
	private monthField: RefObject<HTMLDivElement>;

	public static defaultProps = {
		label: "Datum",
		placeholder: "z.B. " + new Date().toLocaleDateString("de-DE"),
		daterange: false,
		locale: "de-DE",
		ariaLabelNextMonth: "Nächster Monat",
		ariaLabelPreviousMonth: "Vorheriger Monat",
	}

	public constructor(props) {
		super(props);

		let displayedMonth = new Date();

		if (props.preselectedDate) {
			displayedMonth = new Date(props.preselectedDate);
		} else if (props.initialDisplayedMonth) {
			displayedMonth = props.initialDisplayedMonth;
		}

		this.state = {
			displayedMonth: displayedMonth,
			isDatePickerModalVisible: false,
			selectedStartDate: props.preselectedDate || null,
			selectedEndDate: props.preselectedEndDate || null,
			today: new Date(),
			wrongDate: false,
			selectingStartDate: true
		};

		this.nextMonth = this.nextMonth.bind(this);
		this.previousMonth = this.previousMonth.bind(this);
		this.selectDate = this.selectDate.bind(this);
		this.openDatePickerModal = this.openDatePickerModal.bind(this);
		this.closeDatePickerModal = this.closeDatePickerModal.bind(this);
		this.handleDateFieldInput = this.handleDateFieldInput.bind(this);
		this.cancel = this.cancel.bind(this);

		this.monthField = React.createRef();
		this.dateInputField = this.props.inputRef ? this.props.inputRef : React.createRef<HTMLInputElement>();
	}

	private keyboardListener = (event: KeyboardEvent) => {
		if (this.state.isDatePickerModalVisible) {
			// "Left", and "Right" are for IE.
			if (event.key === "ArrowLeft" || event.key === "Left") {
				this.previousMonth(event);
			}

			if (event.key === "ArrowRight" || event.key === "Right") {
				this.nextMonth(event);
			}
		}
	};

	public componentDidMount() {
		window.addEventListener("keydown", this.keyboardListener);
	}

	public componentWillUnmount() {
		window.removeEventListener("keydown", this.keyboardListener);
	}

	private openDatePickerModal() {
		this.selectedStartDateUponOpeningModal = this.state.selectedStartDate;
		this.selectedEndDateUponOpeningModal = this.state.selectedEndDate;
		this.setState({
			isDatePickerModalVisible: true
		});
		// For accessibility reasons, manually set the focus to the month field. This also makes sure the DatePicker modal is immediately navigable via keyboard.
		window.requestAnimationFrame(() => {
			this.monthField.current.focus();
		});
	}

	private closeDatePickerModal() {
		this.setState((state) => ({
			displayedMonth: state.selectedStartDate || new Date(),
			isDatePickerModalVisible: false
		}));
		// For accessibility reasons, set the focus back to the input field. Or rather, the blur, because we do not want "onFocus" to be called again (this is what IE does).
		window.requestAnimationFrame(() => {
			this.dateInputField.current.blur();
		});

		const { onChange } = this.props;
		const { selectedStartDate, selectedEndDate } = this.state;

		onChange && onChange(selectedStartDate, selectedEndDate);
	}

	private cancel() {
		this.setState({
			selectedStartDate: this.selectedStartDateUponOpeningModal,
			selectedEndDate: this.selectedEndDateUponOpeningModal,
			selectingStartDate: true
		});
		this.closeDatePickerModal();
	}

	private nextMonth(event) {
		this.changeMonth(1, event);
	}

	private previousMonth(event) {
		this.changeMonth(-1, event);
	}

	private changeMonth(changeByMonths: number, event: Event) {
		if (this.state.isDatePickerModalVisible) {
			// For accessibility reasons, prevent the default action of the keypress. Otherwise, if the user has enabled a voice assistant, the focus will shift inside the date field (tested with Safari 12).
			event.preventDefault();
			this.setState((state) => ({
				displayedMonth: addMonths(state.displayedMonth, changeByMonths)
			}));
			// For accessibility reasons, manually set the focus to the month field. This way, the screen reader will announce the name of the new month on keypresses.
			window.requestAnimationFrame(() => {
				this.monthField.current.focus();
			});
		}
	}

	private selectDate(date: Date) {
		if (!this.props.daterange || this.state.selectingStartDate) {
			this.setState({
				selectedStartDate: date,
				selectedEndDate: null,
				selectingStartDate: false
			});
		} else {
			// Only apply the new end date if it is after the selected start date
			if (this.state.selectedStartDate.valueOf() < date.valueOf()) {
				this.setState({
					selectedEndDate: date,
					selectingStartDate: true
				});
			} else {
				this.setState((state) => ({
					selectedStartDate: date,
					selectedEndDate: state.selectedStartDate,
					selectingStartDate: true
				}));
			}
		}
	}

	// Normally, it should not be possible to change the value of the date field directly. We expect the user will use only the modal to select the date. Still, it is possible to reach the input field and change its value, therefore, we should make sure this works as intended.
	private handleDateFieldInput(event) {
		this.setState({
			displayedMonth: new Date(event.target.value),
			selectedStartDate: new Date(event.target.value)
		});
	}

	private getDatePickerDayCell(day: Date, partOfCurrentMonth: boolean) {
		const isStartDate = this.state.selectedStartDate && this.state.selectedStartDate.valueOf() === day.valueOf() ? DatePickerStyle["datepicker-start-date"] : "";
		const isEndDate = this.props.daterange && this.state.selectedEndDate && this.state.selectedEndDate.valueOf() === day.valueOf() ? DatePickerStyle["datepicker-end-date"] : "";
		const isInDateRange = this.props.daterange && this.state.selectedStartDate && this.state.selectedEndDate && this.state.selectedStartDate.valueOf() <= day.valueOf() && day.valueOf() <= this.state.selectedEndDate.valueOf() ? DatePickerStyle["datepicker-in-date-range"] : "";
		const isDateToday = isToday(day) ? "font-bold" : "";

		return (
			<button className={`grid-item cursor-pointer ${DatePickerStyle["datepicker-day-cell"]} ${isStartDate} ${isEndDate} ${isInDateRange} ${isDateToday} ${!partOfCurrentMonth ? DatePickerStyle["datepicker-day-cell-outside-of-month"] : ""}`}
				key={day.valueOf()}
				onClick={this.selectDate.bind(this, day)}>
				{ day.toLocaleDateString(this.props.locale, { day: "numeric" }) }
			</button>
		);
	}

	public render() {
		const days: JSX.Element[] = [], weekdaysInTitle: JSX.Element[] = [];
		const daysInMonth = getDaysInMonth(this.state.displayedMonth);

		const firstDayOfMonth = new Date(this.state.displayedMonth.getFullYear(), this.state.displayedMonth.getMonth(), 1);
		let weekdayOfFirstDayOfMonth = getDay(firstDayOfMonth);

		if (weekdayOfFirstDayOfMonth === 0) {
			weekdayOfFirstDayOfMonth = 7;
		}

		// Take a date that we know will be Monday so we can get all weekday names.
		const monday = new Date(1999, 1, 1);

		for (let i = 0; i < 7; i++) {
			weekdaysInTitle.push(
				<div className={`grid-item ${DatePickerStyle["datepicker-weekday-title-cell"]}`} key={i.toString()}>
					{/* Pass undefined instead of "default" so Internet Explorer won't act up */}
					{addDays(monday, i).toLocaleString(this.props.locale, { weekday: "short" }).substr(0, 2)}
				</div>
			);
		}

		// Always fill up six rows of DatePicker days so that the container does not jump when the amount of days changes.
		for (let i = 1 - weekdayOfFirstDayOfMonth; i < (43 - weekdayOfFirstDayOfMonth); i++) {
			const currentDay = addDays(firstDayOfMonth, i);
			const partOfCurrentMonth = i >= 0 && i < daysInMonth;

			const dayCell = this.getDatePickerDayCell(currentDay, partOfCurrentMonth);

			days.push(dayCell);
		}

		let dateValue = this.state.selectedStartDate ? this.state.selectedStartDate.toLocaleDateString(this.props.locale) : "";

		if (this.props.daterange && this.state.selectedEndDate) {
			dateValue += ` - ${this.state.selectedEndDate.toLocaleDateString(this.props.locale)}`;
		}

		const inputProps = {
			"qa-regression-tag": "input-field",
			ref: this.dateInputField
		};

		const dataStartDate = this.state.selectedStartDate ? this.state.selectedStartDate.toISOString() : undefined;
		const dataEndDate = this.state.selectedEndDate ? this.state.selectedEndDate.toISOString() : undefined;

		let cancelButtonClassNames = "";

		if (this.props.cancelButtonClassName) {
			cancelButtonClassNames = `${this.props.cancelButtonClassName} ${DatePickerStyle["datepicker-cancel-button"]}`;
		} else {
			cancelButtonClassNames = `${DatePickerStyle["datepicker-cancel-button"]}`;
		}

		let okButtonClassNames = "";

		if (this.props.okButtonClassName) {
			okButtonClassNames = `${this.props.okButtonClassName} ${DatePickerStyle["datepicker-okay-button"]}`;
		} else {
			okButtonClassNames = `${DatePickerStyle["datepicker-okay-button"]}`;
		}

		return (
			<div>
				<TextInput
					id={this.props.id}
					label={this.props.label}
					iconRight="is24-icon-calendar"
					placeholder={this.props.placeholder}
					defaultValue={dateValue}
					key={dateValue}
					data-start-date={dataStartDate}
					data-end-date={dataEndDate}
					ref={this.props.inputRef}
					onFocus={this.openDatePickerModal}
					onChange={this.handleDateFieldInput}
					errorMessage={this.props.errorMessage}
					valid={this.props.valid}
					success={this.props.success}
					{...inputProps}
					{...this.props.inputProps}
				> </TextInput>
				<Modal
					visible={this.state.isDatePickerModalVisible}
					width={320}
					onClose={this.cancel}
					title={(this.props.headline || this.props.subheadline) && <>
						<span className={DatePickerStyle["datepicker-headline"]}>{this.props.headline}</span>
						<br />
						<span className={DatePickerStyle["datepicker-subheadline"]}>{this.props.subheadline}</span>
					</>}
				>
					<div className="grid grid-flex grid-justify-space-between">
						<button className={`grid-item ${DatePickerStyle["datepicker-previous-month-button"]}`} onClick={this.previousMonth} aria-label={this.props.ariaLabelPreviousMonth}>
							<span className="is24-icon-chevron-left"></span>
						</button>
						{/* Set the tab index explicitly for users with voice assistants. This way, the month gets read whenever the user changes to the next month.  */}
						<div className={`grid-item ${DatePickerStyle["datepicker-current-month"]}`} tabIndex={0} ref={this.monthField} aria-live="polite">{/* eslint-disable-line jsx-a11y/no-noninteractive-tabindex */}
							{ this.state.displayedMonth.toLocaleString(this.props.locale, { month: "long" }) }
							<span> </span>
							{ this.state.displayedMonth.getFullYear() }
						</div>
						<button className={`grid-item ${DatePickerStyle["datepicker-next-month-button"]}`} onClick={this.nextMonth} aria-label={this.props.ariaLabelNextMonth}>
							<span className="is24-icon-chevron-right"></span>
						</button>
					</div>
					<div className={`grid grid-flex ${DatePickerStyle["datepicker-weekdays-in-title"]}`}>
						{weekdaysInTitle}
					</div>
					<div className={`grid grid-flex ${DatePickerStyle["datepicker-days-wrapper"]}`}>
						{days}
					</div>
					<Button className={cancelButtonClassNames} onClick={this.cancel}>Abbrechen</Button>
					<Button appearance="primary" className={okButtonClassNames} onClick={this.closeDatePickerModal}>OK</Button>
				</Modal>
			</div>
		);
	}
}

export { DatePicker };
