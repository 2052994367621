import { AGENT_UPDATE_SUCCESS } from '../actions/agent'

export const initialState = {
  name: null,
  profileImgUrl: null,
  profileUrl: null,
  responseRate: null,
  agency: {
    name: null,
  },
}

export const reducer = (agent = initialState, action) => {
  switch (action.type) {
  case AGENT_UPDATE_SUCCESS:
    return action.agent
  default:
    return agent
  }
}
