import { PARTIES_UPDATE_SUCCESS } from '../actions/parties'

export const initialState = {
  ratingtoken: null,
  leadId: null,
}

export const reducer = (parties = initialState, action) => {
  switch (action.type) {
  case PARTIES_UPDATE_SUCCESS:
    return action.parties
  default:
    return parties
  }
}
