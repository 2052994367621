import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'

import configureStore from './store/configureStore'
import Root from './components/Root'
import { initTracking } from './tracking/tracking'
import { onInit } from './thunks/init'
import initialState from './reducers/initialState'

export const history = createBrowserHistory()

const appId = 'cs24-provider-rating-wep-app'

initTracking()
const store = configureStore(history, initialState)
store.dispatch(onInit())
ReactDOM.render(
  <React.StrictMode>
    <Root store={store} history={history} />
  </React.StrictMode>,
  document.getElementById(appId),
)
