import React, { FC } from "react";
import { FontsProps } from "../FontsProps";
import { Font } from "../Font";

import HeadingStyles from "../Fonts.less";

const Heading1: FC<FontsProps> = ({ className, ...props }) => {
	const headingStyles = `${HeadingStyles["font-h1"]} ${className}`;

	return (
		<Font tag={props.tag} className={headingStyles} {...props} >
			{props.children}
		</Font>
	);
};

Heading1.defaultProps = {
	tag: "h1",
	className: ""
};

export { Heading1 };
