import React, { FC, ReactNode } from "react";
import TabGroupStyles from "./Tabs.less";

type TabListProps = {
	"aria-label": string;
	children: ReactNode;
}


const TabList: FC<TabListProps> = (props) => (
	<div className={TabGroupStyles["tab-list"]} role="tablist" {...props} />
);

export { TabList };
