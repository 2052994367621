import React from 'react'
import { Button } from 'is24-corecss'

import Box from '../common/display/Box'
import { FormattedMessage as Msg } from 'react-intl'
import Shimmer from '../common/display/Shimmer'

const styles = {
  profileImgShimmer: {
    maxWidth: 100,
    height: 40,
  },
  profileImg: {
    width: 38,
    height: 38,
  },
}

const Loading = () =>
  <div className='Loading margin-vertical-xl'>

    <div className='Title font-xxl palm-font-xl align-left'>
      <Msg id='agent.title' defaultMessage='Rate financing provider' />
      <p className='CompanyName palm-hide font-bold'>
        <Shimmer>Andreas Agent</Shimmer>
      </p>
      <span className='CompanyName desk-hide lap-hide font-bold'>
      &nbsp;<Shimmer>Muster</Shimmer>
      &nbsp;<Shimmer>Immobilien</Shimmer>
      </span>
    </div>

    <Box className='Agent margin-top-xxl'>
      <div className='grid grid-flex grid-justify-space-between padding-vertical-m padding-horizontal-xl'>
        <div className='grid-item palm-one-whole'>
          <div className='horizontal-center'>
            <div className='grid grid-flex grid-justify-center grid-align-center'>
              <div className='grid-item grid-item-fixed-width margin-right-m' style={{ lineHeight: 1 }}>
                <Shimmer style={styles.profileImgShimmer}>
                  <div className='ProfileImg' style={styles.profileImg} />
                </Shimmer>
              </div>

              <div className='Name grid-item font-bold'>
                <Shimmer>Andreas Agent</Shimmer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>

    <Box className='Question margin-top-xxl padding-vertical-xl'>
      <div className='Title font-h4'>
        <Shimmer><Msg id='consultationCheck.title' defaultMessage='Did a consultation take place?' /></Shimmer>
      </div>

      <div className='margin-top-xl'>
        <Button className='select-consultation-did-happen button two-fifths palm-five-sixths'>
          <Shimmer>Lorem Ipsum</Shimmer>
        </Button>
      </div>

      <div className='margin-top-l'>
        <Button className='select-consultation-did-not-happen button two-fifths palm-five-sixths'>
          <Shimmer>Lorem Ipsum</Shimmer>
        </Button>
      </div>
    </Box>
  </div>

export default Loading
