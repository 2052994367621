import { connect } from 'react-redux'

import RatingPreview from './RatingPreview'

export default connect(
  state => ({
    user: state.user,
    rating: state.rating,
  }),
)(RatingPreview)

