import { PERCENT } from '../../../domain/common/units'

export const agentToApp = ({
  mortgageProviderProfileName,
  mortgageProviderImage,
  mortgageProviderProfileURL,
  responseRate,
  mortgageProviderCompanyName,
}) => ({
  name: mortgageProviderProfileName || null,
  profileImgUrl: (mortgageProviderImage && mortgageProviderImage.url) || null,
  profileUrl: mortgageProviderProfileURL || null,
  responseRate: { value: responseRate || null, unit: PERCENT },
  agency: {
    name: mortgageProviderCompanyName || null,
  },
})
