import { partiesUpdateSuccess } from '../actions/parties'
import { NotFoundError } from '../domain/common/errors'
import { ROOT } from '../domain/routes'

const parseParties = pathname => {
  const [ leadId, ratingtoken ] = pathname.replace(ROOT, '').split('/').slice(1)
  return { leadId, ratingtoken }
}

const isValid = ({ ratingtoken, leadId }) => ratingtoken && leadId

export const onPartiesInit = () => (dispatch, getState) => {
  const parties = parseParties(getState().router.location.pathname)
  if (isValid(parties)) {
    dispatch(partiesUpdateSuccess(parties))
  } else {
    throw new NotFoundError()
  }
}
