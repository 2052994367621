export const ROOT = '/baufinanzierung/bewerten'
export const ROOT_WITH_LEAD = `${ROOT}/:leadId/:ratingtoken`

export const CONSULTATION_CHECK = `${ROOT_WITH_LEAD}/beratung`
export const CONSULTATION_WHY_NOT = `${ROOT_WITH_LEAD}/keine-beratung-grund`
export const CONSULTATION_THANK_YOU = `${ROOT_WITH_LEAD}/keine-beratung-danke`

export const MORTGAGE_CHECK = `${ROOT_WITH_LEAD}/finanzierung`
export const MORTGAGE_WHY_NOT = `${ROOT_WITH_LEAD}/keine-finanzierung-grund`

export const RATING = `${ROOT_WITH_LEAD}/bewertung`
export const RATING_THANK_YOU = `${ROOT_WITH_LEAD}/bewertung-danke`

export const RETURNING_USER = `${ROOT_WITH_LEAD}/schon-bewertet`

export const ERROR = `${ROOT}/fehler`

export const BUILD_INFO = `${ROOT}/build-info`
