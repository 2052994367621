import React, { Component, ReactNode, MouseEventHandler } from "react";
import { ModalBackdrop } from "./ModalBackdrop";
import { ModalContainer } from "./ModalContainer";

class Modal extends Component<
{
	visible?: boolean;
	title?: ReactNode;
	children?: ReactNode;
	width?: number;
	zIndex?: number;
	onClose: MouseEventHandler;
	ariaLabelCloseButton?: string;
}, {
}> {
	public static defaultProps = {
		visible: false,
		title: false,
		width: 600,
		zIndex: 2000,
		onClose: () => {},
		ariaLabelCloseButton: "Schließen",
	}

	public render() {
		return <ModalBackdrop
			visible={this.props.visible}
			zIndex={this.props.zIndex}
			onClose={this.props.onClose}
		>
			<ModalContainer
				visible={this.props.visible}
				width={this.props.width}
				modalTitle={this.props.title}
				onClose={this.props.onClose}
				ariaLabelCloseButton={this.props.ariaLabelCloseButton}
			>
				{this.props.children}
			</ModalContainer>
		</ModalBackdrop>;
	}
}

export { Modal };
