import {
  MORTGAGE_CHECK,
  CONSULTATION_WHY_NOT,
  CONSULTATION_THANK_YOU,
  RATING,
  MORTGAGE_WHY_NOT,
} from '../domain/routes'
import {
  consultationDidHappen,
  consultationDidNotHappen,
  consultationWhyNot, mortgageNotObtained, mortgageObtained, mortgageWhyNot,
} from '../actions/qualification'
import { onPush, onBack as onRoutingBack } from './routing'
import { sendRating } from '../services/rating/rating'
import { ratingSendFailure } from '../actions/rating'
import { agentUpdateSuccess } from '../actions/agent'

export const onConsultationDidHappen = () => dispatch => {
  dispatch(consultationDidHappen())
  dispatch(onPush(MORTGAGE_CHECK))
}

export const onConsultationDidNotHappen = () => dispatch => {
  dispatch(consultationDidNotHappen())
  dispatch(onPush(CONSULTATION_WHY_NOT))
}

export const onConsultationWhyNot = reason => async (dispatch, getState) => {
  try {
    dispatch(consultationWhyNot(reason))

    const { parties, qualification } = getState()
    const { agent } = await sendRating(parties, {}, qualification)

    dispatch(onPush(CONSULTATION_THANK_YOU))

    await dispatch(agentUpdateSuccess({ ...getState().agent, responseRate: agent.responseRate }))
  } catch (error) {
    dispatch(ratingSendFailure(error))
  }
}

export const onMortgageObtained = () => dispatch => {
  dispatch(mortgageObtained())
  dispatch(onPush(RATING))
}

export const onMortgageNotObtained = () => dispatch => {
  dispatch(mortgageNotObtained())
  dispatch(onPush(MORTGAGE_WHY_NOT))
}

export const onMortgageWhyNot = reason => dispatch => {
  dispatch(mortgageWhyNot(reason))
  dispatch(onPush(RATING))
}

export const onBack = () => dispatch => dispatch(onRoutingBack())
