export const CONSULTATION_DID_HAPPEN = 'CONSULTATION_DID_HAPPEN'
export const CONSULTATION_DID_NOT_HAPPEN = 'CONSULTATION_DID_NOT_HAPPEN'

export const consultationDidHappen = () => ({ type: CONSULTATION_DID_HAPPEN })
export const consultationDidNotHappen = () => ({ type: CONSULTATION_DID_NOT_HAPPEN })

export const CONSULTATION_WHY_NOT = 'CONSULTATION_WHY_NOT'

export const consultationWhyNot = reason => ({ type: CONSULTATION_WHY_NOT, reason })

export const MORTGAGE_OBTAINED = 'MORTGAGE_OBTAINED'
export const MORTGAGE_NOT_OBTAINED = 'MORTGAGE_NOT_OBTAINED'

export const mortgageObtained = () => ({ type: MORTGAGE_OBTAINED })
export const mortgageNotObtained = () => ({ type: MORTGAGE_NOT_OBTAINED })

export const MORTGAGE_WHY_NOT = 'MORTGAGE_WHY_NOT'

export const mortgageWhyNot = reason => ({ type: MORTGAGE_WHY_NOT, reason })
