import React, { RefForwardingComponent, ReactChild } from "react";

import ToggleSwitchStyles from "./ToggleSwitch.less";

type ToggleSwitchProps = {
	/** Give an id so that your onChange listener can tell you which field is the currently checked one. */
	id: string;
	/** Labels can be either strings or JSX */
	label: ReactChild;
} & JSX.IntrinsicElements["input"];

/**
 * Toggle switches provide the same functionality as checkboxes but are more widely used in mobile environments.
 */
const ToggleSwitch: RefForwardingComponent<HTMLInputElement, ToggleSwitchProps> = React.forwardRef(({
	label,
	className,
	...otherProps
}, ref) => (
	<div className={ToggleSwitchStyles["toggle-switch-container"]}>
		<input
			type="checkbox"
			className={`${ToggleSwitchStyles["input-toggle-switch"]} ${className}`}
			ref={ref}
			{...otherProps}
		/>
		<label
			className={ToggleSwitchStyles["label-toggle-switch"]}
			htmlFor={otherProps.id}
		>
			{label}
		</label>
	</div>
));

ToggleSwitch.defaultProps = {
	defaultChecked: false,
	onChange: () => {}
};

export { ToggleSwitch };
