export const RATING_PROPERTY_UPDATE_SUCCESS = 'RATING_PROPERTY_UPDATE_SUCCESS'

export const ratingPropertyUpdate = (property, value) => ({ type: RATING_PROPERTY_UPDATE_SUCCESS, property, value })

export const RATING_SEND_FAILURE = 'RATING_SEND_FAILURE'

export const ratingSendFailure = error => ({ type: RATING_SEND_FAILURE, error })

export const RATING_INVALID = 'RATING_INVALID'

export const ratingInvalid = rating => ({ type: RATING_INVALID, rating })

export const RATING_UPDATE_SUCCESS = 'RATING_UPDATE_SUCCESS'

export const ratingUpdateSuccess = rating => ({ type: RATING_UPDATE_SUCCESS, rating })
