import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { Button } from 'is24-corecss'
import PropTypes from 'prop-types'
import camelCase from 'lodash.camelcase'
import kebabCase from 'lodash.kebabcase'

import ConnectedCompanyName from '../../agent/ConnectedCompanyName'
import { MORTGAGE_WHY_NOT_REASONS } from '../../../domain/common/Qualification/mortgageWhyNotReasons'
import ConnectedAgent from '../../agent/ConnectedAgent'
import Box from '../../common/display/Box'

const styles = {
  button: {
    whiteSpace: 'normal',
  },
}

const MortgageWhyNot = ({ onMortgageWhyNot, onBack }) => {
  return (
    <div className='MortgageWhyNot margin-vertical-xl'>

      <ConnectedCompanyName />

      <Box className='margin-top-xxl'>
        <ConnectedAgent />
      </Box>

      <Box className='margin-top-xxl padding-vertical-xl' style={styles.box}>
        <div className='Title font-h4'>
          <Msg id='mortgageWhyNot.title' defaultMessage='Why have you not gotten a mortgage?' />
        </div>

        {MORTGAGE_WHY_NOT_REASONS.map(reason =>
          <div key={reason} className='margin-top-l'>
            <Button className={`select-${kebabCase(reason)} two-fifths palm-five-sixths`} style={styles.button} onClick={() => onMortgageWhyNot(reason)}>
              <Msg id={`mortgageWhyNot.${camelCase(reason)}`} defaultMessage={reason} />
            </Button>
          </div>,
        )}

      </Box>

      <p className='go-back margin-top-m margin-bottom-xxl float-left' onClick={() => onBack()}>
        <button className='link-text' style={{ textDecoration: 'none' }}>
          <span className='is24-icon-arrow-left margin-right-s' />
          <Msg id='qualification.back' defaultMessage='Back' />
        </button>
      </p>
    </div>
  )
}

MortgageWhyNot.propTypes = {
  onMortgageWhyNot: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default MortgageWhyNot
