import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate as Date, FormattedMessage as Msg } from 'react-intl'

import Box from '../common/display/Box'
import ConnectedAgent from '../agent/ConnectedAgent'
import ConnectedRatingPreview from './ConnectedRatingPreview'
import MyMortgageTeaser from './MyMortgageTeaser'
import Banner from '../common/display/Banner'
import { INFO } from '../../domain/message/messageTypes'
import BannerTitle from '../common/display/BannerTitle'
import BannerBody from '../common/display/BannerBody'

const styles = {
  divider: {
    borderTop: '1px solid #E0E0E0',
  },
}

const ReturningUser = ({ date }) =>
  <div className='ReturningUser margin-vertical-xl'>

    <Banner appearance={INFO}>
      <BannerTitle>
        <Msg id='rating.thankYou.title' defaultMessage='Thank you for your feedback' />
      </BannerTitle>
      <BannerBody>
        <Msg id='rating.returningUser.alreadyReviewedPt1' defaultMessage='You have already reviewed this provider.' />
        &nbsp;
        <Date value={date} year='numeric' month='2-digit' day='2-digit' />
        &nbsp;
        <Msg id='rating.returningUser.alreadyReviewedPt2' defaultMessage='You have already reviewed this provider.' />
      </BannerBody>
    </Banner>

    <Box className='margin-top-xxl'>
      <ConnectedAgent showAgentProfileUrl />
      <div style={styles.divider} />
      <ConnectedRatingPreview />
    </Box>

    <Box className='margin-top-xxl'>
      <MyMortgageTeaser />
    </Box>
  </div>

ReturningUser.propTypes = {
  date: PropTypes.string.isRequired,
}

export default ReturningUser
