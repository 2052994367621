import { MESSAGE_SHOW, MESSAGE_HIDE } from '../actions/message'

export const initialState = null

export const reducer = (message = initialState, action) => {
  switch (action.type) {
  case MESSAGE_SHOW:
    return action.message
  case MESSAGE_HIDE:
    return initialState
  default:
    return message
  }
}
