import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  root: {
    overflow: 'hidden',
    borderRadius: 4,
    border: '1px solid #d8d8d8',
  },

}

const Box = ({ className, children }) =>
  <div
    className={'Box align-center background-white' + (className ? ' ' + className : '')}
    style={{ ...styles.root }}
  >
    {children}
  </div>

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Box
