import { combineReducers } from 'redux'
import { intlReducer as intl } from 'react-intl-redux'
import { connectRouter } from 'connected-react-router'

import { reducer as initStatus } from './initStatus'
import { reducer as qualification } from './qualification'
import { reducer as parties } from './parties'
import { reducer as rating } from './rating'
import { reducer as ratingValidation } from './ratingValidation'
import { reducer as agent } from './agent'
import { reducer as user } from './user'
import { reducer as message } from './message'

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  intl,
  initStatus,
  qualification,
  parties,
  rating,
  ratingValidation,
  agent,
  user,
  message,
})

export default createRootReducer
