import React, { Component } from "react";
import ReactDOM from "react-dom";

import modalStyle from "./Modal.less";

class ModalBackdrop extends Component<
{
	visible?: boolean;
	zIndex?: number;
	onClose: Function;
} & JSX.IntrinsicElements["div"], {
}> {
	public static defaultProps = {
		visible: false,
		zIndex: 2000,
		onClose: () => {},
	}

	public componentDidMount() {
		window.addEventListener("click", this.clickAnywhereToDismiss);
		window.addEventListener("keydown", this.keyboardListener);
	}

	public componentWillUnmount() {
		window.removeEventListener("click", this.clickAnywhereToDismiss);
		window.removeEventListener("keydown", this.keyboardListener);
	}

	private keyboardListener = (event: KeyboardEvent) => {
		if (this.props.visible) {
			// "Esc" is for IE.
			if (event.key === "Escape" || event.key === "Esc") {
				this.close();
			}
		}
	};

	private clickAnywhereToDismiss = (event) => {
		// Only close if the user targeted the area outside the modal
		if (event.target.classList.contains(modalStyle["modal-backdrop"])) {
			this.close();
		}
	};

	private close = () => {
		this.props.onClose();
	}

	public render() {
		const className = [
			modalStyle["modal-backdrop"],
			this.props.className
		].filter(className => !!className).join(" ");

		return this.props.visible &&
		ReactDOM.createPortal(
			<div
				aria-modal={true}
				className={className}
				style={{ zIndex: this.props.zIndex }}
			>
				{this.props.children}
			</div>,
			document.body
		);
	}
}

export { ModalBackdrop };
