import { connect } from 'react-redux'

import ReturningUser from './ReturningUser'

export default connect(
  state => ({
    date: state.rating.date,
  }),
)(ReturningUser)

