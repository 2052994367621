import React from 'react'
import Banner from '../common/display/Banner'
import BannerTitle from '../common/display/BannerTitle'
import PropTypes from 'prop-types'
import { FormattedDate as Date, FormattedMessage as Msg } from 'react-intl'
import BannerBody from '../common/display/BannerBody'
import { INFO } from '../../domain/message/messageTypes'

const BannerRatingAlreadyExists = ({ date }) =>
  <Banner appearance={INFO}>
    <BannerTitle>
      <Msg id='rating.info.title' defaultMessage='This is your rating from {date}' values={{ date: <Date value={date} year='numeric' month='2-digit' day='2-digit' /> }} />
    </BannerTitle>
    <BannerBody>
      <Msg id='rating.info.text' defaultMessage='Your can edit and resend your feedback' />
    </BannerBody>
  </Banner>

BannerRatingAlreadyExists.propTypes = {
  date: PropTypes.string.isRequired,
}

export default BannerRatingAlreadyExists
