import React from 'react'
import Sticky from 'react-sticky-el'

import ConnectedRoutes from './ConnectedRoutes'
import ConnectedMessage from './common/message/ConnectedMessage'

// This is a class-based component because the current version of hot reloading won't hot reload a stateless component
// at the top-level.

class App extends React.Component {
  render() {
    return <>
      <Sticky mode='top' style={{ zIndex: 5 }}>
        <ConnectedMessage />
      </Sticky>
      <ConnectedRoutes />
    </>
  }
}

export default App
