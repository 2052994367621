import { TRACKING_BUNDLE_URL } from '../config'

export const initTracking = () => {
  if (process.env.NODE_ENV !== 'production') return

  let script = document.createElement('script')
  script.src = TRACKING_BUNDLE_URL
  script.id = 'tealium-script'
  script.async = true
  document.body.appendChild(script)
}

export const report = event => {
  setTimeout(() => {
    if (event.label) trackEvent(event)
    else trackPageView(event)
  }, window.IS24 && window.IS24.TEALIUM ? 0 : 1000)
}

const trackPageView = ({ page }) => {
  if (!window.IS24 || !window.IS24.TEALIUM) return
  window.IS24.TEALIUM.tracking.report(
    { pag_pagetitle: `is24.de.baufinanzierung/rating/${page}` },
    { reportType: 'UTAG_VIEW' },
  )
}

const trackEvent = ({ page, label, action, category }) => {
  if (!window.IS24 || !window.IS24.TEALIUM) return
  window.IS24.TEALIUM.tracking.report({
    evt_ga_category: category,
    evt_ga_action: action,
    evt_ga_label: label,
    pag_pagetitle: `is24.de.baufinanzierung/rating/${page}`,
  })
}
