import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

import ConnectedRatingPreview from './ConnectedRatingPreview'
import ConnectedAgent from '../agent/ConnectedAgent'
import Box from '../common/display/Box'
import MyMortgageTeaser from './MyMortgageTeaser'
import BannerTitle from '../common/display/BannerTitle'
import BannerBody from '../common/display/BannerBody'
import { CONFIRM } from '../../domain/message/messageTypes'
import Banner from '../common/display/Banner'

const styles = {
  divider: {
    borderTop: '1px solid #E0E0E0',
  },
}

const RatingThankYou = () =>
  <div className='RatingThankYou margin-vertical-xl'>

    <Banner appearance={CONFIRM}>
      <BannerTitle>
        <Msg id='rating.thankYou.title' defaultMessage='Thank you for your feedback' />
      </BannerTitle>
      <BannerBody>
        <Msg id='rating.thankYou.text' defaultMessage='Your feedback will be online in 15 minutes.' />
      </BannerBody>
    </Banner>

    <Box className='margin-top-xxl'>
      <ConnectedAgent showAgentProfileUrl />
      <div style={styles.divider} />
      <ConnectedRatingPreview />
    </Box>

    <Box className='margin-top-xxl'>
      <MyMortgageTeaser />
    </Box>
  </div>

export default RatingThankYou
