import React from 'react'
import PropTypes from 'prop-types'

const BannerBody = ({ children }) =>
  <div className='Body'>
    {children}
  </div>

BannerBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BannerBody
