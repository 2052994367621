import React, { Component, ReactNode, RefObject, MouseEventHandler } from "react";

import modalStyle from "./Modal.less";

class ModalContainer extends Component<
{
	visible?: boolean;
	modalTitle?: ReactNode;
	width?: number;
	onClose: MouseEventHandler;
	ariaLabelCloseButton?: string;
	/** The class that should be added to the modal container */
	containerClassName?: string;
	/** The class that should be added to the wrapper of the scrollable modal content */
	contentWrapperClassName?: string;
} & JSX.IntrinsicElements["div"], {
}> {
	private modalRef: RefObject<HTMLDivElement>;

	public static defaultProps = {
		visible: false,
		modalTitle: false,
		width: 600,
		onClose: () => {},
		ariaLabelCloseButton: "Schließen",
	}

	public constructor(props) {
		super(props);

		this.modalRef = React.createRef<HTMLDivElement>();
	}

	private FOCUSABLE = [
		"a[href]",
		"button:not([disabled])",
		"input:not([disabled])",
		"object",
		"select:not([disabled])",
		"textarea:not([disabled])",
		"[tabindex='0']",
	];

	private focusableNodes: NodeListOf<HTMLElement> | null;

	private firstFocusableNode: HTMLElement | null;
	private lastFocusableNode: HTMLElement | null;

	public componentDidMount() {
		window.addEventListener("keydown", this.keyboardListener);
		this.setupFocusTrap();
	}

	public componentWillUnmount() {
		window.removeEventListener("keydown", this.keyboardListener);
	}

	public componentDidUpdate() {
		this.setupFocusTrap();
	}

	private setupFocusTrap = () => {
		const focusableSelectors = this.FOCUSABLE.join(",");

		this.focusableNodes = this.modalRef.current && this.modalRef.current.querySelectorAll(focusableSelectors);
		this.firstFocusableNode = this.focusableNodes && this.focusableNodes[0];
		if (this.firstFocusableNode) {
			this.firstFocusableNode.blur();
		}
		this.lastFocusableNode = this.focusableNodes && this.focusableNodes[this.focusableNodes.length - 1];
	}

	private keyboardListener = (event: KeyboardEvent) => {
		if (this.props.visible) {
			if (event.key === "Tab") {
				// trap focus to modal content
				if (event.shiftKey && document.activeElement === this.firstFocusableNode) {
					// cycle to last node from first node
					event.preventDefault();
	
					const lastElement = this.lastFocusableNode;
	
					if (lastElement) {
						lastElement.focus();
					}
				} else if (!event.shiftKey && document.activeElement === this.lastFocusableNode) {
					// cycle to first node from last node
					event.preventDefault();
					const firstElement = this.firstFocusableNode;
	
					if (firstElement) {
						firstElement.focus();
					}
				}
			}
		}
	};

	public render() {
		const containerClassName = [
			modalStyle["modal-container"],
			this.props.containerClassName
		].filter(className => !!className).join(" ");
		
		const contentWrapperClassName = [
			modalStyle["modal-content-wrapper"],
			this.props.contentWrapperClassName,
			this.props.modalTitle && modalStyle["with-scroll-shadow"]
		].filter(className => !!className).join(" ");

		return <div
			className={containerClassName}
			style={{ width: this.props.width }}
			qa-regression-tag="modal-wrapper"
			ref={this.modalRef}
		>
			<button className={modalStyle["modal-close-button"]} aria-label={this.props.ariaLabelCloseButton} onClick={this.props.onClose}>
				<span className="is24-icon-closing"></span>
			</button>
			{this.props.modalTitle && <div className={modalStyle["modal-title-wrapper"]}>
				<div className={modalStyle["modal-headline"]}>
					{this.props.modalTitle}
				</div>
			</div>}
			<div className={contentWrapperClassName}>
				{this.props.children}
			</div>
		</div>;
	}
}

export { ModalContainer };
