import React, { ReactChild, RefForwardingComponent } from "react";

import textAreaStyles from "./TextArea.less";
import fontStyles from "../../Fonts/Fonts.less";


type TextAreaProps = {
	id: string;
	placeholder: string;
	errorMessage?: string;
	/** Labels can be either strings or JSX */
	label: ReactChild;
	helperText?: string;
	valid?: boolean;
	success?: boolean;
	maxLength?: number;
}
& JSX.IntrinsicElements["textarea"];

const TextArea: RefForwardingComponent<HTMLTextAreaElement, TextAreaProps> = React.forwardRef(({
	errorMessage,
	label,
	helperText,
	valid,
	success,
	className,
	// Make sure `children` is not used anywhere by declaring it here without using it.
	children,
	...props
}, ref) => {
	const containerClassName = `${textAreaStyles["textarea-container"]}`;
	let textFieldClassName = `${textAreaStyles["textarea"]} ${className}`;

	if (!valid) {
		textFieldClassName += ` ${textAreaStyles["error"]}`;
	}

	if (success) {
		textFieldClassName += ` ${textAreaStyles["success"]}`;
	}

	return (
		<div className={containerClassName}>
			{label && <label className={textAreaStyles["input-label"]} htmlFor={props.id}>{label}</label>}
			<textarea
				ref={ref}
				className={textFieldClassName}
				{...props}
			/>
			<span className={textAreaStyles["input-label-helper-text"]}>{helperText}</span>
			{errorMessage && !valid && <span className={`${textAreaStyles["input-label-helper-text"]} ${fontStyles["font-error"]}`}>{errorMessage}</span>}
		</div>
	);
});

TextArea.defaultProps = {
	placeholder: "Please provide a placeholder (or use an empty string)",
	className: "",
	errorMessage: "",
	label: "Please provide a label for accessibility reasons (or use an empty string)",
	helperText: "",
	valid: true,
	success: false
};

export { TextArea };
