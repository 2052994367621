import PropTypes from 'prop-types'

export const RatingValidationProps = {
  conditions: PropTypes.bool,
  product: PropTypes.bool,
  analysis: PropTypes.bool,
  competence: PropTypes.bool,
  accessibility: PropTypes.bool,
  clarity: PropTypes.bool,
}

export const RatingValidationShape = PropTypes.shape(RatingValidationProps)
