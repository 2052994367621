import React, { FC } from "react";

import CardStyles from "../Card.less";

type CardBodyProps = {
	/** Class property goes directly in tooltip's root div. */
	className?: string;
	/** Class property goes directly in tooltip's root div. */
	linkAddress?: string;
} & JSX.IntrinsicElements["div"];

/**
 *
 */
const CardBody: FC<CardBodyProps> = ({
	children,
	linkAddress,
	className,
	...otherProps
}) => {
	const cardClassNames = [
		className,
		CardStyles["card-body"]
	].filter(className => !!className).join(" ");

	return (
		<div className={cardClassNames} {...otherProps}>
			{children}
		</div>
	);
};

export { CardBody };
