import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  color: '#F2CA26',
}

const Star = ({ filled, onClick }) =>
  <span
    className={`Star font-xl cursor-pointer is24-icon-rating-state-${filled ? 3 : 1 }`} style={styles}
    onClick={() => onClick()}
  />

Star.propTypes = {
  filled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default Star
