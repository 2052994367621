import React, { forwardRef, ReactChild, RefForwardingComponent } from "react";

import selectFieldStyles from "./SelectField.less";
import fontStyles from "../../Fonts/Fonts.less";

type SelectFieldProps = {
	isErroneous?: boolean;
	errorMessage?: string;
	/** Labels can be either strings or JSX */
	label?: ReactChild;
	helperText?: string;
	iconLeft?: string;
	dropdownLink?: boolean;
	dropdownLinkIconOnly?: boolean;
	dropdownLinkNoTextOnMobile?: boolean;
} & JSX.IntrinsicElements["select"];

/**
 * SelectFields are straightforward to create. If you want to listen to changes, you can do so via the \`onFocus\`, \`onBlur\`, and \`onChange\` events.
 */
const SelectField: RefForwardingComponent<HTMLSelectElement, SelectFieldProps> = forwardRef(({
	className,
	isErroneous,
	errorMessage,
	label,
	helperText,
	iconLeft,
	dropdownLink,
	dropdownLinkIconOnly,
	dropdownLinkNoTextOnMobile,
	...otherProps
}, ref) => {
	const getClassNames = (props: SelectFieldProps) => {
		let containerClassName = props.className ? props.className : "";
		let iconClassName;
		let selectClassName;
	
		if (props.dropdownLink) {
			containerClassName += ` ${selectFieldStyles["dropdown-link"]}`;
			if (props.dropdownLinkIconOnly) {
				containerClassName += ` ${selectFieldStyles["dropdown-link-icon-only"]}`;
			} else {
				if (props.iconLeft) {
					containerClassName += ` ${selectFieldStyles["dropdown-link-with-icon"]}`;
				}
				if (props.dropdownLinkNoTextOnMobile) {
					containerClassName += ` ${selectFieldStyles["palm-dropdown-link-icon-only"]}`;
				}
			}
			iconClassName = selectFieldStyles["dropdown-link-icon"];
			selectClassName = "";
		} else {
			containerClassName += ` ${selectFieldStyles["select-container"]}`;

			if (otherProps.disabled) {
				containerClassName += ` ${selectFieldStyles["disabled"]}`;
			}

			iconClassName = selectFieldStyles["input-icon-left"];
			selectClassName = selectFieldStyles["select"];
		}
	
		if (props.isErroneous) {
			selectClassName += ` ${selectFieldStyles["error"]}`;
		}

		return {
			containerClassName,
			iconClassName,
			selectClassName
		};
	};

	const { containerClassName, iconClassName, selectClassName } = getClassNames({
		className,
		isErroneous,
		errorMessage,
		label,
		helperText,
		iconLeft,
		dropdownLink,
		dropdownLinkIconOnly,
		dropdownLinkNoTextOnMobile,
	});

	const selectEl = <select
		className={selectClassName}
		ref={ref}
		{...otherProps}
	>
	</select>;

	return (
		<div className={containerClassName}>
			{label && !dropdownLink && <label className={selectFieldStyles["input-label"]} htmlFor={otherProps.id}>{label}</label>}
			{!dropdownLink &&
			<div className={selectFieldStyles["select-input-wrapper"]}>
				{iconLeft.length > 0 && <span className={`${iconClassName} ${iconLeft}`}></span>}
				{selectEl}
			</div> }
			{dropdownLink &&
				<React.Fragment>
					{iconLeft.length > 0 && <span className={`${iconClassName} ${iconLeft}`}></span>}
					{selectEl}
				</React.Fragment>
			}
			<span className={selectFieldStyles["input-label-helper-text"]}>{helperText}</span>
			{errorMessage && <span className={`${selectFieldStyles["input-label-helper-text"]} ${fontStyles["font-error"]}`}>{errorMessage}</span>}
		</div>
	);
});

SelectField.defaultProps = {
	isErroneous: false,
	errorMessage: "",
	label: "Please provide a label for accessibility reasons (or use an empty string)",
	helperText: "",
	iconLeft: "",
	dropdownLink: false,
	dropdownLinkIconOnly: false,
	dropdownLinkNoTextOnMobile: false,
};

export { SelectField };
