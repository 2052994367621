import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'
import { Button } from 'is24-corecss'

import ConnectedCompanyName from '../../agent/ConnectedCompanyName'
import ConnectedAgent from '../../agent/ConnectedAgent'
import Box from '../../common/display/Box'

const ConsultationCheck = ({ onConsultationDidHappen, onConsultationDidNotHappen }) =>
  <div className='ConsultationCheck margin-vertical-xl'>

    <ConnectedCompanyName />

    <Box className='margin-top-xxl'>
      <ConnectedAgent />
    </Box>

    <Box className='margin-top-xxl padding-vertical-xl'>
      <div className='Title font-h4'>
        <Msg id='consultationCheck.title' defaultMessage='Did a consultation take place?' />
      </div>

      <div className='margin-top-xl'>
        <Button className='select-consultation-did-happen two-fifths palm-five-sixths' onClick={() => onConsultationDidHappen()}>
          <Msg id='qualification.yes' defaultMessage='Yes' />
        </Button>
      </div>

      <div className='margin-top-l'>
        <Button className='select-consultation-did-not-happen two-fifths palm-five-sixths' onClick={() => onConsultationDidNotHappen()}>
          <Msg id='qualification.no' defaultMessage='No' />
        </Button>
      </div>
    </Box>

  </div>

ConsultationCheck.propTypes = {
  onConsultationDidHappen: PropTypes.func.isRequired,
  onConsultationDidNotHappen: PropTypes.func.isRequired,
}

export default ConsultationCheck
