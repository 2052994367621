import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'
import { Button } from 'is24-corecss'

import ConnectedCompanyName from '../../agent/ConnectedCompanyName'
import ConnectedAgent from '../../agent/ConnectedAgent'
import Box from '../../common/display/Box'

const MortgageCheck = ({ onMortgageObtained, onMortgageNotObtained, onBack }) =>
  <div className='MortgageCheck margin-vertical-xl'>

    <ConnectedCompanyName />

    <Box className='margin-top-xxl'>
      <ConnectedAgent />
    </Box>

    <Box className='margin-top-xxl padding-vertical-xl'>
      <div className='Title font-h4'>
        <Msg id='mortgageCheck.title' defaultMessage='Have you gotten a mortgage?' />
      </div>

      <div className='margin-top-xl'>
        <Button className='select-mortgage-obtained two-fifths palm-five-sixths' onClick={() => onMortgageObtained()}>
          <Msg id='qualification.yes' defaultMessage='Yes' />
        </Button>
      </div>

      <div className='margin-top-l'>
        <Button className='select-mortgage-not-obtained two-fifths palm-five-sixths' onClick={() => onMortgageNotObtained()}>
          <Msg id='qualification.no' defaultMessage='No' />
        </Button>
      </div>
    </Box>

    <p className='go-back margin-top-m margin-bottom-xxl float-left' onClick={() => onBack()}>
      <button className='link-text' style={{ textDecoration: 'none' }}>
        <span className='is24-icon-arrow-left margin-right-s' />
        <Msg id='qualification.back' defaultMessage='Back' />
      </button>
    </p>
  </div>

MortgageCheck.propTypes = {
  onMortgageObtained: PropTypes.func.isRequired,
  onMortgageNotObtained: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default MortgageCheck
