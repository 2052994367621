import React from 'react'
import { connect } from 'react-redux'

import Routes from './Routes'
import Loading from './loading/Loading'

export default connect(
  state => ({
    initialising: state.initStatus.initialising,
  }),
)(({ initialising }) => initialising ? <Loading /> : <Routes />)
