import React, { RefForwardingComponent, ReactChild } from "react";

import RadioButtonStyles from "./RadioButton.less";

type RadioButtonProps = {
	/** Give an id so that your onChange listener can tell you which field is the currently checked one. */
	id: string;
	/** Labels can be either strings or JSX */
	label: ReactChild;
	/** Provide a name so that radio buttons can form a group. All radio buttons in a group have to share the same name. */
	name: string;
	isErroneous?: boolean;
} & JSX.IntrinsicElements["input"];

const RadioButton: RefForwardingComponent<HTMLInputElement, RadioButtonProps> = React.forwardRef(({
	label,
	isErroneous,
	...otherProps
}, ref) => {
	const errorClassName = isErroneous ? `${RadioButtonStyles["error"]}` : "";

	return (
		<div className={RadioButtonStyles["radio-container"]}>
			<input
				type="radio"
				className={`${RadioButtonStyles["input-radio"]}`}
				ref={ref}
				{...otherProps}
			/>
			<label
				className={`${RadioButtonStyles["label-radio"]} ${errorClassName}`}
				htmlFor={otherProps.id}
			>
				{label}
			</label>
		</div>
	);
});

RadioButton.defaultProps = {
	defaultChecked: false,
};

export { RadioButton };
