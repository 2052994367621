import React from 'react'
import PropTypes from 'prop-types'

import './shimmer.css'

const styles = {
  shimmer: {
    top: '0.29em',
    bottom: 0,
    left: '-0.1em',
    right: '-0.1em',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: 'placeHolderShimmer',
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #eeeeee 8%, #e6e5e5 18%, #f3f3f3 33%)',
    backgroundSize: '800px 104px',
    borderRadius: '4px',
  },
}

const Shimmer = ({ children, style }) =>
  <span className='Shimmer inline-block absolute-reference'>
    <span className='ShimmerContent absolute-content' style={{ ...styles.shimmer, ...style }} />
    {children}
  </span>

Shimmer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

export default Shimmer
