// Some JS wizardry taken from
// https://stackoverflow.com/a/2117523/2677658
function uuidv4(): string {
	const oneE7: any = [1e7]; // eslint-disable-line

	let cryptoImplementation;

	// Access msCrypto via angle brackets to make TypeScript compiler happy
	if (window["msCrypto"]) {
		cryptoImplementation = window["msCrypto"];
	} else {
		cryptoImplementation = crypto;
	}

	return (oneE7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		  (c ^ cryptoImplementation.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

export { uuidv4 };
