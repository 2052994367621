import { MISSED_CALL, NO_CALL, NO_REACH_OUT } from '../../../domain/common/Qualification/consultationWhyNot'

export const API_REASON_NO_CONTACT = 'NO_CONTACT'
export const API_REASON_UNABLE_TO_TALK = 'UNABLE_TO_TALK'
export const API_REASON_UNWILLING_TO_TALK = 'UNWILLING_TO_TALK'

const APP_REASON_TO_API_REASON = {
  [NO_REACH_OUT]: API_REASON_NO_CONTACT,
  [MISSED_CALL]: API_REASON_UNABLE_TO_TALK,
  [NO_CALL]: API_REASON_UNWILLING_TO_TALK,
}

export const toApi = reason => APP_REASON_TO_API_REASON[reason]
