import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../../../thunks/qualification'
import ConsultationCheck from './ConsultationCheck'

export default connect(
  null,
  dispatch => bindActionCreators(actions, dispatch),
)(ConsultationCheck)
