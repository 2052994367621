import React, { FC } from "react";

import TooltipStyles from "./Tooltip.less";

type TooltipContainerProps = {
	/** Class property goes directly in TooltipContainer's root div. */
	className?: string;
} & JSX.IntrinsicElements["div"];

/**
 * Tooltips can be used to provide more or specific information and can contain everything from strings to whole forms with CTAs.
 */
const TooltipContainer: FC<TooltipContainerProps> = ({
	children,
	className,
	...otherProps
}) => {
	const containerClassName = [
		"CosmaTooltipContainer",
		className,
		TooltipStyles["tooltip-container"],
	].filter(className => !!className).join(" ");

	return (
		<div className={containerClassName} {...otherProps}>
			{children}
		</div>
	);
};

export { TooltipContainer };
