import React, { FC } from "react";

import indicatorStyles from "./Indicator.less";

type IndicatorProps = {
	className?: string;
	expanded?: boolean;
	float?: boolean;
	appearance: "dot" | "numeric" | "label" | "tag" | "brand";
} & JSX.IntrinsicElements["div"];

/**
 * Indicators are visual indicators to emphasize an information. Visual indicators are used to make certain items stand out from the crowd.
 * They don't require the user to take action, but act as communication tools to cue something noteworthy.
 * Indicators are not always present but appear under certain conditions.
 *
 * A badge is a quick visual indicator like a dot on top of an icon or other elements.
 *
 * A numeric-badge is a quick visual indicator for a numeric value either next to an element or inside the element itself.
 *
 * A label is a visual indicator for a specific characteristic of an element. A label wants to highlight a unique property of an element. They are actionless.
 *
 * Tags and Tags with a cross represent a number of characteristics of an object and/or a category of an object. The only difference between these two elements is that tags are not clickable by default, but tags with a cross icon are.
 */
const Indicator: FC<IndicatorProps> = ({ children, appearance, expanded, float, ...props }) => {
	const indicatorClassName = [
		indicatorStyles["indicator"],
		appearance === "tag" && indicatorStyles["indicator--tag"],
		appearance === "label" && indicatorStyles["indicator--label"],
		appearance === "brand" && indicatorStyles["indicator--brand"],
		appearance === "numeric" && indicatorStyles["indicator--numeric"],
		appearance === "dot" && indicatorStyles["indicator--dot"],
		float && indicatorStyles["indicator--float"],
		expanded && indicatorStyles["indicator--expanded"],
		props.className
	].filter(className => !!className).join(" ");

	// This is necessary to pass validation when an Indicator has an onClick event
	// The role and tabIndex attributes are required in order to enhance accessibility
	const accessibilityProps = props.onClick && {
		role: "button",
		onClick: props.onClick,
		tabIndex: 0,
	};

	return (
		<div className={indicatorClassName}
			{...accessibilityProps}
			{...props}
		>
			{children &&
				<span>{children}
					{ appearance === "tag" && props.onClick && <span className="is24-icon-closing"></span> }
				</span>
			}
		</div>
	);
};

export { Indicator };
