import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

import Box from '../../common/display/Box'
import ConnectedAgent from '../../agent/ConnectedAgent'
import ConnectedResponseRate from '../../agent/ConnectedResponseRate'
import MyMortgageTeaser from '../../rating/MyMortgageTeaser'
import BannerTitle from '../../common/display/BannerTitle'
import BannerBody from '../../common/display/BannerBody'
import Banner from '../../common/display/Banner'
import { INFO } from '../../../domain/message/messageTypes'

const styles = {
  divider: {
    borderTop: '1px solid #E0E0E0',
  },
}

const NoConsultationThankYou = () =>
  <div className='NoConsultationThankYou margin-vertical-xl'>
    <Banner appearance={INFO}>
      <BannerTitle>
        <Msg id='noConsultationThankYou.title' defaultMessage='Thank you for your feedback' />
      </BannerTitle>
      <BannerBody>
        <Msg id='noConsultationThankYou.text' defaultMessage='As no consultation took place, you cannot give any feedback on the consultation.' />
      </BannerBody>
    </Banner>
    <Box className='margin-top-xxl'>
      <ConnectedAgent showAgentProfileUrl />
      <div style={styles.divider} />
      <ConnectedResponseRate />
    </Box>

    <Box className='margin-top-xxl'>
      <MyMortgageTeaser />
    </Box>
  </div>

export default NoConsultationThankYou
