import React, { FC, ReactNode } from "react";
import TabGroupStyles from "./Tabs.less";


type TabProps = {
	iconClass?: string;
	children: ReactNode;
}

const Tab: FC<TabProps> = ({
	iconClass,
	children,
}) => (
	<button
		className={TabGroupStyles["tab"]}
		role="tab"
		aria-selected="false">
		{iconClass ? <i className={iconClass} /> : ""}
		{children}
	</button>
);

export { Tab };
