import { initialState as initStatus } from './initStatus'
import { initialState as qualification } from './qualification'
import { initialState as parties } from './parties'
import { initialState as rating } from './rating'
import { initialState as ratingValidation } from './ratingValidation'
import { initialState as agent } from './agent'
import { initialState as user } from './user'
import { initialState as message } from './message'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initStatus,
  qualification,
  parties,
  rating,
  ratingValidation,
  agent,
  user,
  message,
}
