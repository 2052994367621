import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

import { AgentShape } from '../../domain/agent/AgentShape'

const styles = {
  profileImgWrapper: {
    maxWidth: 100,
    maxHeight: 40,

  },
  profileImg: {
    maxWidth: 100,
    maxHeight: 40,
  },
  nameWrapper: {
    maxWidth: 235,
  },
}

const Agent = ({ agent: { name, profileImgUrl, profileUrl }, showAgentProfileUrl = false }) =>
  <div className='Agent grid grid-flex grid-justify-space-between padding-vertical-m padding-horizontal-xl'>

    <div className='grid-item palm-one-whole'>
      <div className='horizontal-center'>
        <div className='grid grid-flex grid-justify-center grid-align-center'>

          <div className='grid-item grid-item-fixed-width margin-right-m' style={styles.profileImgWrapper}>
            <img className='ProfileImg' style={styles.profileImg} src={profileImgUrl} alt='Agent' />
          </div>

          <div className='Name grid-item font-bold' style={styles.nameWrapper}>
            {name}
          </div>

        </div>
      </div>
    </div>

    {showAgentProfileUrl &&
      <div className='AgentProfileLink grid-item palm-one-whole grid-align-center align-center'>
        <a className='go-to-profile' href={profileUrl} target='_blank' rel='noopener noreferrer'>
          <Msg id='agent.link' defaultMessage='To the provider profile' />
        </a>
      </div>
    }
  </div>

Agent.propTypes = {
  agent: AgentShape.isRequired,
  showAgentProfileUrl: PropTypes.bool,
}

export default Agent
