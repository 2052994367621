import { initSuccess, initFailure } from '../actions/init'
import { onLocaleSwitch } from './intl'
import { deDE } from '../intl/locales'
import { onTrackPageVisit } from './tracking'
import { onReplace } from './routing'
import { onPartiesInit } from './parties'
import { onExternalDataInit } from './externalData'
import {
  CONSULTATION_CHECK,
  CONSULTATION_WHY_NOT,
  CONSULTATION_THANK_YOU,
  MORTGAGE_CHECK,
  MORTGAGE_WHY_NOT,
  RETURNING_USER,
  ROOT,
  BUILD_INFO,
  ERROR,
} from '../domain/routes'

export const onInit = () => async (dispatch, getState) => {
  try {
    await dispatch(onLocaleSwitch(deDE))
    if (requiresSimpleInit(getState().router.location.pathname)) {
      await dispatch(onSimpleInit())
    } else {
      await dispatch(onComplexInit())
    }
  } catch (error) {
    await dispatch(initFailure(error))
  }
}

const onSimpleInit = () => async dispatch => {
  await dispatch(onTrackPageVisit())
  await dispatch(initSuccess())
}

const onComplexInit = () => async (dispatch, getState) => {
  await dispatch(onPartiesInit())

  let shouldTrack = true
  if (shouldRedirect(getState().router.location.pathname)) {
    await dispatch(onReplace(CONSULTATION_CHECK))
    shouldTrack = false
  }

  await dispatch(onExternalDataInit())

  if (shouldRedirectToReturningUserPage(getState().rating)) {
    await dispatch(onReplace(getState().rating.review ? RETURNING_USER : CONSULTATION_THANK_YOU))
    shouldTrack = false
  }

  if (shouldTrack) {
    await dispatch(onTrackPageVisit())
  }

  await dispatch(initSuccess())
}

const requiresSimpleInit = path => !path.startsWith(ROOT) || [
  BUILD_INFO,
  ERROR,
].some(route => path.startsWith(route))

const shouldRedirect = path => ![
  BUILD_INFO,
  ERROR,
  RETURNING_USER,
  MORTGAGE_WHY_NOT,
  MORTGAGE_CHECK,
  CONSULTATION_WHY_NOT,
  CONSULTATION_CHECK,
  CONSULTATION_THANK_YOU,
].some(route => route.endsWith(path.split('/').filter(segment => segment).pop()))

const shouldRedirectToReturningUserPage = rating => rating.date && rating.isContractCompleted
