import {
  CONSULTATION_DID_HAPPEN,
  CONSULTATION_DID_NOT_HAPPEN,
  CONSULTATION_WHY_NOT,
  MORTGAGE_NOT_OBTAINED,
  MORTGAGE_OBTAINED,
  MORTGAGE_WHY_NOT,
} from '../actions/qualification'

export const initialState = {
  consultationDidHappen: null,
  consultationWhyNot: null,
  mortgageObtained: null,
  mortgageWhyNot: null,
}

export const reducer = (qualification = initialState, action) => {
  switch (action.type) {
  case CONSULTATION_DID_HAPPEN:
    return { ...qualification, consultationDidHappen: true }
  case CONSULTATION_DID_NOT_HAPPEN:
    return { ...qualification, consultationDidHappen: false }
  case CONSULTATION_WHY_NOT:
    return { ...qualification, consultationDidHappen: false, mortgageObtained: false, consultationWhyNot: action.reason }
  case MORTGAGE_OBTAINED:
    return { ...qualification, consultationDidHappen: true, mortgageObtained: true }
  case MORTGAGE_NOT_OBTAINED:
    return { ...qualification, consultationDidHappen: true, mortgageObtained: false }
  case MORTGAGE_WHY_NOT:
    return { ...qualification, consultationDidHappen: true, mortgageObtained: false, mortgageWhyNot: action.reason }
  default:
    return qualification
  }
}
