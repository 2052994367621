import React from 'react'
import PropTypes from 'prop-types'

import Star from './Star'

const Rating = ({ value, onChange }) =>
  <span className='align-middle'>
    {[ ...Array(5) ].map((_, i) =>
      <Star key={i}
        filled={i + 1 <= value}
        onClick={() => onChange(i + 1 === value ? null : i + 1)}
      />,
    )}
  </span>

Rating.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}
export default Rating
