import PropTypes from 'prop-types'

import { UnitShape } from './UnitShape'

export const QuantityProps = {
  value: PropTypes.number,
  unit: UnitShape,
}

export const QuantityShape = PropTypes.shape(QuantityProps)
