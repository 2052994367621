import React, { RefForwardingComponent, forwardRef } from "react";

import buttonStyle from "./Button.less";

type ButtonProps = {
	appearance?: "primary" | "secondary" | "ghost";
	iconStandalone?: boolean;
	textStyle?: boolean;
	size?: "small" | "medium" | "large";
	renderContainer?: (props) => JSX.Element;
} & JSX.IntrinsicElements["button"];

/**
 * Buttons communicate actions that users can take. They allow users to make choices with one single tap.
 *
 * Each button should have a proper label and, optionally, an icon. The meaning should be clear and always tie back to what it will do for the user because users feel more comfortable when they understand what action a button does. Tell users what the outcome will be after pressing the button. Such explicit labeling serves as just-in-time help, giving users confidence in selecting the action.
 */
const Button: RefForwardingComponent<HTMLButtonElement, ButtonProps> = forwardRef(({
	appearance,
	className,
	iconStandalone,
	textStyle,
	size,
	renderContainer,
	...otherProps
}, ref) => {
	const getButtonClassNames = ({
		appearance,
		className,
		iconStandalone,
		textStyle,
		size
	}) => {
		const buttonClasses = [
			className,
			!textStyle && !iconStandalone && appearance === "ghost" && buttonStyle["button"], // Button__button
			size && appearance === "ghost" && iconStandalone && buttonStyle["button"],
			!textStyle && appearance && buttonStyle[`button-${appearance}`],
			!iconStandalone && size && buttonStyle[`button-${size}`],
			!size && iconStandalone && appearance === "ghost" && buttonStyle["button-icon-standalone"],
			size && iconStandalone && buttonStyle[`button-icon-standalone-${size}`],
			appearance !== "secondary" && textStyle && buttonStyle["link-text"], // Button__link-text
			appearance === "secondary" && textStyle && buttonStyle["link-text-secondary"] // Button__link-text-secondary
		];
	
		return buttonClasses.filter(className => !!className).join(" ");
	};

	const buttonClassName = getButtonClassNames({
		appearance,
		className,
		iconStandalone,
		textStyle,
		size
	});

	// We need a way to allow creating a button with a different tag. We get there through renderContainer.
	// The idea with renderContainer is a result from TypeScript not allowing all attributes that you would expect from, say, an <a> tag element if we just change the tag name. Because of this, we need to expose the creation of the tag itself so the appropriate attributes can be derived directly.
	// This idea is explained here: https://blog.andrewbran.ch/polymorphic-react-components/
	return renderContainer({
		ref,
		className: buttonClassName,
		"qa-regression-tag": "button",
		...otherProps
	});
});

Button.defaultProps = {
	appearance: "ghost",
	textStyle: false,
	renderContainer: props => <button {...props} />
};

export { Button };
