import React, { ReactChild, RefForwardingComponent } from "react";

import textInputStyles from "./TextInput.less";
import fontStyles from "../../Fonts/Fonts.less";

type TextInputProps = {
	/** Give an id so that the label can properly assigned to the input field. */
	id: string;
	/** A placeholder inside the input field that is only shown when the input field is empty. */
	placeholder: string;
	/** To provide user feedback underneath the input field why the input is invalid. */
	errorMessage?: string;
	/** Labels can be either strings or JSX */
	label: ReactChild;
	/** To provide additional information underneath the input field. */
	helperText?: string;
	/** Should the input field render in error state with error message? */
	valid?: boolean;
	/** Should the input field render in success state? */
	success?: boolean;
	/** Render an affix container (containing an icon or a unit) on the left side. */
	affixLeft?: "none" | "unit" | "icon";
	/** Which unit should be rendered in the left affix container? (affixLeft required) */
	unitLeft?: "none" | "percent" | "sqm" | "eur" | "eurPerSqm" | "years" | "months";
	/** Which icon should be rendered in the left affix container? <is24-icon-class-name> (affixLeft required) */
	iconLeft?: string;
	/** Render an affix container (containing an icon or a unit) on the right side. */
	affixRight?: "none" | "unit" | "icon";
	/** Which unit should be rendered in the right affix container? (affixRight required) */
	unitRight?: "none" | "percent" | "sqm" | "eur" | "eurPerSqm" | "years" | "months";
	/** Which icon should be rendered in the right affix container? <is24-icon-class-name> (affixRight required) */
	iconRight?: string;
}
& Omit<JSX.IntrinsicElements["input"], "children">

const UNITS = {
	percent: "%",
	sqm: "m²",
	eur: "\u20AC",
	eurPerSqm: "\u20AC/m²",
	years: "a",
	months: "m",
};

const TextInput: RefForwardingComponent<HTMLInputElement, TextInputProps> = React.forwardRef(({
	errorMessage,
	label,
	helperText,
	affixLeft, unitLeft, iconLeft,
	affixRight, unitRight, iconRight,
	valid,
	success,
	className,
	// Make sure `children` is not used anywhere by declaring it here without using it.
	children,
	...props
}, ref) => {
	const containerClassName = `${textInputStyles["input-text-container"]}`;
	let textFieldClassName = `${textInputStyles["input-text"]} ${className}`;

	if (!valid) {
		textFieldClassName += ` ${textInputStyles["error"]}`;
	}

	if (success) {
		textFieldClassName += ` ${textInputStyles["success"]}`;
	}

	return (
		<div className={containerClassName}>
			{label && <label className={textInputStyles["input-label"]} htmlFor={props.id}>{label}</label>}
			{affixLeft !== "none" &&
				<span className={`${textInputStyles["affix-left"]} ${affixLeft === "unit" ? textInputStyles["affix-unit"] : iconLeft}`}>
					{unitLeft !== "none" && UNITS[unitLeft]}
				</span>
			}
			{affixRight !== "none" &&
				<span className={`${textInputStyles["affix-right"]} ${affixRight === "unit" ? textInputStyles["affix-unit"] : iconRight}`}>
					{unitRight !== "none" && UNITS[unitRight]}
				</span>
			}
			<input
				ref={ref}
				className={textFieldClassName}
				{...props}
			/>
			<span className={textInputStyles["input-label-helper-text"]}>{helperText}</span>
			{errorMessage && !valid && <span className={`${textInputStyles["input-label-helper-text"]} ${fontStyles["font-error"]}`}>{errorMessage}</span>}
		</div>
	);
});

TextInput.defaultProps = {
	placeholder: "Please provide a placeholder (or use an empty string)",
	className: "",
	errorMessage: "",
	label: "Please provide a label for accessibility reasons (or use an empty string)",
	helperText: "",
	type: "text",
	valid: true,
	success: false,
	affixLeft: "none",
	unitLeft: "none",
	iconLeft: "",
	affixRight: "none",
	unitRight: "none",
	iconRight: "",
};

export { TextInput };
