import React, { RefForwardingComponent, ReactChild } from "react";

import CheckboxStyles from "./Checkbox.less";

type CheckboxProps = {
	/** Give an id so that your onChange listener can tell you which field is the currently checked one. */
	id: string;
	/** Labels can be either strings or JSX */
	label: ReactChild;
	isErroneous?: boolean;
	indeterminate?: boolean;
} & JSX.IntrinsicElements["input"]

const Checkbox: RefForwardingComponent<HTMLInputElement, CheckboxProps> = React.forwardRef(({
	label,
	isErroneous,
	indeterminate = false,
	...otherProps
}, ref) => {
	const errorClassName = isErroneous
		? `${CheckboxStyles["error"]}`
		: "";

	return (
		<div className={CheckboxStyles["checkbox-container"]}>
			<input
				type="checkbox"
				className={CheckboxStyles["input-checkbox"]}
				ref={ref => ref && (ref.indeterminate = indeterminate)}
				{...otherProps}
			/>
			<label
				className={`${CheckboxStyles["label-checkbox"]} ${errorClassName}`}
				htmlFor={otherProps.id}
			>
				{label}
			</label>
		</div>
	);
});

export { Checkbox };
