import PropTypes from 'prop-types'
import { QuantityShape } from '../common/QuantityShape'

export const AgentProps = {
  name: PropTypes.string.isRequired,
  profileImgUrl: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
  agency: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  responseRate: QuantityShape.isRequired,
}

export const AgentShape = PropTypes.shape(AgentProps)
