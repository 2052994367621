import { INIT_FAILURE, INIT_SUCCESS } from '../actions/init'

export const initialState = {
  initialising: true,
}

export const reducer = (initStatus = initialState, action) => {
  switch (action.type) {
  case INIT_FAILURE:
  case INIT_SUCCESS:
    return { initialising: false }
  default:
    return initStatus
  }
}
