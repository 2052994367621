import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

import Quantity from '../common/display/Quantity'
import { QuantityShape } from '../../domain/common/QuantityShape'
import Ring from '../common/display/Ring'

const ResponseRate = ({ responseRate }) =>
  <div className='ResponseRate align-left padding-l'>
    <p className='Title font-l font-semibold padding-bottom-s'><Msg id='agent.responseRate.title' defaultMessage='Response Rate' />  </p>
    <p className='Text font-lightgray padding-bottom-m'><Msg id='agent.responseRate.text' defaultMessage='Response Rate' />  </p>
    <div className='RateGraphic grid grid-flex gutter-horizontal-m'>
      <div className='inline-block grid-item grid-align-center'>
        <Ring value={(responseRate.value / 100)} />
      </div>
      <div className='inline-block grid-item'>
        <span className='Text font-m palm-font-s'><Msg id='agent.responseRate.rate' defaultMessage='Response Rate' /></span>
        <span className='Rate font-m palm-font-s padding-left-s font-bold'><Quantity quantity={responseRate} /></span>
      </div>
    </div>
  </div>

ResponseRate.propTypes = {
  responseRate: QuantityShape.isRequired,
}

export default ResponseRate
