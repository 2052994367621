import React, { Component } from "react";
import { uuidv4 } from "../helpers";

import AccordionStyles from "./Accordion.less";

// focusedElement has to be initialized as undefined or null to avoid autofocus
type focusedElement = number | undefined | null;

type AccordionProps = {
	children: React.ReactNode;
	id?: string;
	onToggle?: (event: number) => void;
	className?: string;
} & JSX.IntrinsicElements["div"];

type AccordionState = {
	currentlyFocused: focusedElement;
}

class Accordion extends Component<AccordionProps, AccordionState> {
	private uid = this.props.id || uuidv4();

	public constructor(props) {
		super(props);

		this.state = {
			currentlyFocused: null
		};
	}

	// The KeyboardEvent  is the type of whichever element onKeyDown is attached to
	public onNavigation = (nav: React.KeyboardEvent<HTMLDivElement>) => {
		const { currentlyFocused } = this.state;
		const { key } = nav;
		// The length of the accordion, including elements that were not rendered on the screen
		const accordionLength = React.Children.count(this.props.children);
		// In order to support IE 11 we have to use "Up" and "Down".
		// https://www.w3.org/TR/uievents-key/#named-key-attribute-values
		// "Left" and "Right" are anti-patterns:
		// https://www.w3.org/TR/wai-aria-practices/#accordion

		switch (key) {
			case "Down":
			case "ArrowDown":
				if (currentlyFocused >= accordionLength - 1) {
					// The accordion is a doubly linked list so if the user presses Down key on the last item, it should go to the first one.
					this.setFocus(0);
				} else {
					this.setFocus(currentlyFocused + 1);
				}
				break;
			case "Up":
			case "ArrowUp":
				// The accordion is a doubly linked list so if the user presses Up key on the first item, it should go to the last one.
				if (currentlyFocused <= 0) {
					this.setFocus(accordionLength - 1);
				} else {
					this.setFocus(currentlyFocused - 1);
				}
				break;
			case "Home":
				this.setFocus(0);
				break;
			case "End":
				this.setFocus(accordionLength - 1);
				break;
			default:
		}
	}

	public setFocus = (current: focusedElement) => {
		this.setState({
			currentlyFocused: current
		});
	}

	public render() {
		const { children, className, onToggle } = this.props;
		const { currentlyFocused } = this.state;
		const accordionClassName = [
			AccordionStyles["accordion"],
			className
		].filter(className => !!className).join(" ");

		const inheritedProps = {
			currentlyFocused: currentlyFocused,
			id: this.uid,
			onNavigation: this.onNavigation,
			onToggle,
			setFocus: this.setFocus
		};

		return (
			<div className={accordionClassName} id={`is24-accordion-${this.uid}`} {...this.props} >
				<>
					{React.Children.map(children as React.ReactElement, (child, index) => {
						// Removes elements that are empty, doesn't have props or content
						if (!child || !child.props || !child.props.children) { return null; }

						return React.cloneElement(child, {
							...child.props,
							index,
							...inheritedProps
						});
					})}
				</>
			</div>
		);
	}
}

export { Accordion };
