import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber as No } from 'react-intl'

import { QuantityShape } from '../../../domain/common/QuantityShape'

const Quantity = ({ quantity: { value }, fractionDigits = 0 }) => {
  const digits = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  // eslint-disable-next-line react/style-prop-object
  return value === null ? <span className='Unit'>- %</span> : <No value={value / 100} style='percent' {...digits} />
}

Quantity.propTypes = {
  quantity: QuantityShape.isRequired,
  fractionDigits: PropTypes.number,
}

export default Quantity
