import React from 'react'
import { FormattedDate } from 'react-intl'

import { UserShape } from '../../domain/user/userShape'
import Rating from '../common/display/Rating'
import { calculateTotalRating } from '../../domain/rating/calculateTotalRating'
import { RatingShape } from '../../domain/rating/RatingShape'

const RatingPreview = ({ user, rating, rating: { review, date = null } }) =>
  <div className='RatingPreview padding-horizontal-xl padding-vertical-m grid grid-flex grid-justify-space-between background-white'>
    <div className='grid-item'>
      <div className='UserName font-bold'>
        <p>{user.name}</p>
      </div>
      <div className='Date font-lightgray'>
        <FormattedDate value={date ? date : new Date()} year='numeric' month='2-digit' day='2-digit' />
      </div>
    </div>

    <div className='RatingTotal grid-item grid-align-center'>
      <Rating value={calculateTotalRating(rating)} />
    </div>

    <div className='Review grid-item one-whole margin-top-m font-normal'>{review}</div>
  </div>

RatingPreview.propTypes = {
  user: UserShape.isRequired,
  rating: RatingShape.isRequired,
}

export default RatingPreview
