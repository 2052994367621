import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import camelCase from 'lodash.camelcase'

import {
  CODE_OF_CONDUCT_OFFENCES,
  FORMAL_OFFENSES,
} from '../../domain/rating/codeOfConduct'

const styles = {
  bullet: {
    position: 'relative',
    left: 8,
  },
}

const CodeOfConductContent = () =>
  <div className='ReviewCodeOfConductContent padding-horizontal-l padding-top-l padding-bottom-xxl'>

    <div className='font-semibold'>
      <Msg className='font-bold' id='rating.codeOfConduct.paragraph1' defaultMessage='Please note the evaluation code when writing your rating.' />
    </div>

    <div className='font-semibold margin-top-m'>
      <Msg className='font-bold' id='rating.codeOfConduct.paragraph2' defaultMessage='ImmobilienScout24 reserves the right to immediately delete the rating in case of violations of the Code.' />
    </div>

    <div className='font-semibold margin-top-m'>
      <Msg className='font-bold' id='rating.codeOfConduct.paragraph3' defaultMessage='Reviews are written respectfully, honestly and objectively should not contain any:' />
    </div>

    <ul className='list-bullet margin-left-s'>
      {CODE_OF_CONDUCT_OFFENCES.map(offense =>
        <li key={offense} className={offense === FORMAL_OFFENSES ? 'margin-top-l' : ''}>
          <span style={styles.bullet}>
            <Msg className='font-bold' id={`rating.codeOfConduct.${camelCase(offense)}`} defaultMessage={offense} />
          </span>
        </li>,
      )}
    </ul>
  </div>

export default CodeOfConductContent
