import React from 'react'
import PropTypes from 'prop-types'

const BannerTitle = ({ children }) =>
  <h3 className='Title'>
    {children}
  </h3>

BannerTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BannerTitle
