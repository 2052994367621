import PropTypes from 'prop-types'

import { MessageTypeShape } from './messageTypeShape'

export const MessageProps = {
  type: MessageTypeShape.isRequired,
  titleId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
}

export const MessageShape = PropTypes.shape(MessageProps)
