import {
  ALTERNATIVE_OFFERS,
  CONSULTATION_INCOMPLETE, MORTGAGE_NOT_NEEDED,
  MORTGAGE_WITH_ANOTHER_PROVIDER,
} from '../../../domain/common/Qualification/mortgageWhyNotReasons'

export const API_REASON_ONGOING_CONSULTING = 'ONGOING_CONSULTING'
export const API_REASON_OTHER_PROVIDER_CHOSEN = 'OTHER_PROVIDER_CHOSEN'
export const API_REASON_SEARCHING_ALTERNATIVES = 'SEARCHING_ALTERNATIVES'
export const API_REASON_NO_FINANCING_DESIRED = 'NO_FINANCING_DESIRED'

const APP_REASON_TO_API_CONTRACT_REASON = {
  [CONSULTATION_INCOMPLETE]: API_REASON_ONGOING_CONSULTING,
  [MORTGAGE_WITH_ANOTHER_PROVIDER]: API_REASON_OTHER_PROVIDER_CHOSEN,
  [ALTERNATIVE_OFFERS]: API_REASON_SEARCHING_ALTERNATIVES,
  [MORTGAGE_NOT_NEEDED]: API_REASON_NO_FINANCING_DESIRED,
}

export const toApi = reason => APP_REASON_TO_API_CONTRACT_REASON[reason]
