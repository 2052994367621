import React, { FC } from "react";

import PopoverStyles from "./Popover.less";

type PopoverProps = {
	/** Is the popover visible or not? (default: false) */
	visible?: boolean;
	/** Class property goes directly in popover's root div. */
	className?: string;
	/** The position of popover's arrow along the edge. */
	arrowPosition?: "left" | "center" | "right";
	/** The position of popover itself in relation to target element. */
	bodyPosition?: "top" | "right" | "bottom" | "left";
} & JSX.IntrinsicElements["div"];

/**
 * Popovers can be used to provide more or specific information and can contain everything from strings to whole forms with CTAs.
 * You control when they have to close and to show, so you can see and interact with different parts of the page while the popover is still shown.
 */
const Popover: FC<PopoverProps> = ({
	children,
	visible,
	className,
	arrowPosition,
	bodyPosition,
	...otherProps
}) => {
	const arrowClassName = PopoverStyles[`arrow-${arrowPosition}`];
	const bodyClassName = PopoverStyles[`body-${bodyPosition}`];
	const visibleClassName = !visible ? PopoverStyles["hidden"] : null;

	return (
		<div className={`Popover ${className} ${visibleClassName} ${PopoverStyles["popover"]} ${arrowClassName} ${bodyClassName}`} {...otherProps}>
			{children}
		</div>
	);
};

Popover.defaultProps = {
	visible: false,
	arrowPosition: "center",
	bodyPosition: "bottom",
};

export { Popover };
