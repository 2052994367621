import PropTypes from 'prop-types'

export const RatingProps = {
  conditions: PropTypes.number,
  product: PropTypes.number,
  analysis: PropTypes.number,
  competence: PropTypes.number,
  accessibility: PropTypes.number,
  clarity: PropTypes.number,
  date: PropTypes.string,
}

export const RatingShape = PropTypes.shape(RatingProps)
