import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

import Rating from '../common/input/Rating'

const RatingSection = ({ section, subSection1, subSection2, rating1, ratingValidation1, rating2, ratingValidation2, onChange }) =>
  <div className='RatingSection padding-vertical-l padding-horizontal-xl align-left border-bottom'>

    <div className='font-h4'>
      <span className='SectionTitle'>
        <Msg id={`rating.sections.${section}`} defaultMessage={section} />
      </span>
    </div>

    <div className='Categories grid grid-flex gutter-l'>
      <div className='grid-item one-half palm-one-whole'>
        <div className={`Sub1Text ${ratingValidation1 === false ? 'font-error' : null} `}>
          <Msg
            id={`rating.sections.${subSection1}`}
            defaultMessage='Conditions'
            values={{ br: <br /> }}
          />
        </div>
        <div className={`Sub1 rating-${subSection1} padding-top-s`}>
          <Rating value={rating1} onChange={rating => onChange({ property: subSection1, value: rating })} />
        </div>
      </div>

      <div className='grid-item one-half palm-one-whole'>
        <div className={`Sub2Text ${ratingValidation2 === false ? 'font-error' : null} `}>
          <Msg
            id={`rating.sections.${subSection2}`}
            defaultMessage='Product'
            values={{ br: <br /> }}
          />
        </div>
        <div className={`Sub2 rating-${subSection2} padding-top-s`}>
          <Rating value={rating2} onChange={rating => onChange({ property: subSection2, value: rating })} />
        </div>
      </div>
    </div>
  </div>

RatingSection.propTypes = {
  section: PropTypes.string.isRequired,
  subSection1: PropTypes.string.isRequired,
  subSection2: PropTypes.string.isRequired,
  rating1: PropTypes.number,
  rating2: PropTypes.number,
  ratingValidation1: PropTypes.bool,
  ratingValidation2: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default RatingSection
