import { agentToApp } from '../../agent/converters/agent'
import { ratingToApp } from '../../rating/converters/rating'
import { userToApp } from '../../user/converters/user'

export const leadToApp = ({
  mortgageProviderRatingDetailAO = {},
  ...agent
}) => ({
  rating: ratingToApp(mortgageProviderRatingDetailAO),
  agent: agentToApp(agent),
  user: userToApp(mortgageProviderRatingDetailAO),
})
