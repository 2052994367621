import React, { FC } from "react";

import CardStyles from "../Card.less";

type CardHeaderProps = {
	/** Class property goes directly in tooltip's root div. */
	className?: string;
} & JSX.IntrinsicElements["div"];

/**
 *
 */
const CardHeader: FC<CardHeaderProps> = ({
	children,
	className,
	...otherProps
}) => {
	const cardHeaderClassNames = [
		className,
		CardStyles["card-header"]
	].filter(className => !!className).join(" ");

	return (
		<div className={cardHeaderClassNames} {...otherProps}>
			{children}
		</div>
	);
};

export { CardHeader };
