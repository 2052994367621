import React from "react";
import AccordionStyles from "../Accordion.less";

const AccordionIcon = ({ className }) => {
	return (
		<>
			<span className={`is24-icon-chevron-down ${AccordionStyles["accordion-icon"]} ${className}`} />
		</>
	);
};

export { AccordionIcon };
