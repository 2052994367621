import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'
import { Button } from 'is24-corecss'
import camelCase from 'lodash.camelcase'
import kebabCase from 'lodash.kebabcase'

import { CONSULTATION_WHY_NOT_REASONS } from '../../../domain/common/Qualification/consultationWhyNot'
import ConnectedAgent from '../../agent/ConnectedAgent'
import Box from '../../common/display/Box'
import ConnectedCompanyName from '../../agent/ConnectedCompanyName'

const styles = {
  button: {
    whiteSpace: 'normal',
  },
  backButton: {
    paddingLeft: 0,
  },
}

const ConsultationWhyNot = ({ onConsultationWhyNot, onBack }) =>
  <div className='ConsultationWhyNot margin-vertical-xl'>

    <ConnectedCompanyName />

    <Box className='margin-top-xxl'>
      <ConnectedAgent />
    </Box>

    <Box className='margin-top-xxl padding-vertical-xl'>
      <div className='Title font-h4'>
        <Msg id='consultationWhyNot.title' defaultMessage='Why did no consultation take place?' />
      </div>

      {CONSULTATION_WHY_NOT_REASONS.map(reason =>
        <div key={reason} className='margin-top-xl'>
          <Button className={`select-${kebabCase(reason)} two-fifths palm-five-sixths`} style={styles.button} onClick={() => onConsultationWhyNot(reason)}>
            <Msg id={`consultationWhyNot.buttons.${camelCase(reason)}`} defaultMessage={reason} />
          </Button>
        </div>,
      )}
    </Box>

    <p className='go-back margin-top-m margin-bottom-xxl float-left' onClick={() => onBack()}>
      <button className='link-text' style={{ textDecoration: 'none' }}>
        <span className='is24-icon-arrow-left margin-right-s' />
        <Msg id='qualification.back' defaultMessage='Back' />
      </button>
    </p>

  </div>

ConsultationWhyNot.propTypes = {
  onConsultationWhyNot: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default ConsultationWhyNot
