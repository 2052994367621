import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

import { MessageShape } from '../../../domain/message/MessageShape'

const styles = {
  cancel: {
    top: 0,
    right: 0,
  },
}

const Message = ({ message: { type, titleId, contentId }, onMessageHide }) =>
  <div className={`Message absolute-reference status-message status-message--short status-${type.toLowerCase()}`}>
    <span className='cancel absolute-content is24-icon-closing padding-s font-m cursor-pointer' style={styles.cancel} onClick={() => onMessageHide()} />
    <h4 className='Title font-tabular palm-font-s'><Msg id={titleId} defaultMessage={titleId} /></h4>
    <p className='Content palm-font-s'><Msg id={contentId} defaultMessage={contentId} /></p>
  </div>

Message.propTypes = {
  message: MessageShape.isRequired,
  onMessageHide: PropTypes.func.isRequired,
}

export default Message
