import React, { FC } from "react";

import CardDividerStyles from "../Card.less";

type CardDividerProps = {
	/** Class property goes directly in card footer container div. */
	className?: string;
};

/**
 *
 */
const CardDivider: FC<CardDividerProps> = ({
	className,
	...otherProps
}) => {
	const cardDividerClassNames = [
		className,
		CardDividerStyles["card-divider"]
	].filter(className => !!className).join(" ");

	return (
		<hr className={cardDividerClassNames} {...otherProps}></hr>
	);
};

export { CardDivider };
