import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import imageSource from './issue-tower.svg'

const Error = () =>
  <div className='Error margin-xl'>
    <div className='grid grid-flex gutter grid-justify-center'>
      <div className='grid-item one-whole align-center'>
        <img src={imageSource} alt='Pisa Tower' />
      </div>
      <div className='grid-item one-whole align-center margin-bottom-m font-xl'>
        <Msg id='message.somethingWrong' defaultMessage='Unfortunately something went wrong' />
      </div>
      <div className='grid-item one-whole align-center'>
        <Msg id='message.pleaseCheckConnection' defaultMessage='Please check connection' />
      </div>
    </div>
  </div>

export default Error
