import React, { useState } from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { Checkbox, Modal } from 'is24-corecss'
import PropTypes from 'prop-types'

import TextArea from '../common/input/TextArea'
import CodeOfConductContent from './CodeOfConductContent'
import { UserShape } from '../../domain/user/userShape'
import Disclaimer from './Disclaimer'

const styles = {
  checkboxWrapper: {
    marginLeft: -13,
  },
}

const ReviewSection = ({ review, reviewValid, agreed, agreedValid, user, onChange }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  return (
    <div className='ReviewSection padding-horizontal-xl padding-vertical-l border-bottom'>

      <div className='Subtitle font-h5 align-left'>
        <Msg id='rating.reviewTitle' defaultMessage='Tell us about your experience' />
      </div>

      <TextArea
        className='Review margin-top-m'
        value={review || undefined}
        onChange={review => onChange({ property: 'review', value: review })}
        valid={reviewValid !== false}
      />

      <div className='align-left' style={styles.checkboxWrapper}>
        <Checkbox
          id='agreed'
          label={<Disclaimer user={user} onCodeOfConductOpen={() => setIsOpen(true)} />}
          checked={agreed}
          isErroneous={agreedValid === false}
          onChange={({ target: { checked } }) => onChange({ property: 'agreed', value: checked })}
        />
      </div>

      <Modal
        className='CodeOfConductPopup'
        visible={isOpen}
        title={<Msg id='rating.codeOfConduct.title' defaultMessage='Review code of conduct' />}
        onClose={() => setIsOpen(false)}>
        <CodeOfConductContent />
      </Modal>
    </div>
  )
}

ReviewSection.propTypes = {
  review: PropTypes.string,
  reviewValid: PropTypes.bool,
  agreed: PropTypes.bool,
  agreedValid: PropTypes.bool,
  user: UserShape.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ReviewSection
