exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Primary */\n/* Primary */\n/* Primary */\n/* Primary */\n/* Primary */\n/* Primary */\n/* Primary */\n.yuSZdMGcH7mji3Jzt9xjP {\n  min-width: 250px;\n  min-height: 300px;\n  width: 250px;\n  border: 1px solid #d6d6d6;\n  padding: 0 16px;\n}\n.yuSZdMGcH7mji3Jzt9xjP,\n[data-theme=\"core\"] .yuSZdMGcH7mji3Jzt9xjP {\n  border-radius: 0;\n}\n[data-theme=\"cosma\"] .yuSZdMGcH7mji3Jzt9xjP {\n  border-radius: 4px;\n}\n.yuSZdMGcH7mji3Jzt9xjP > a,\n.yuSZdMGcH7mji3Jzt9xjP > a:hover,\n.yuSZdMGcH7mji3Jzt9xjP > a:visited {\n  text-decoration: none;\n}\n.jD-RN5wOOaD2atIMrNGW {\n  display: block;\n  height: 280px;\n}\n.WSLQzNsO0-L-immdQKkaf {\n  display: block;\n  height: 72px;\n}\n.tWycaRNcjxZsnubCzO4HW {\n  display: block;\n  height: 72px;\n}\n._1-ij6lD0FLlP7Z6QspbLX- {\n  background-color: #d6d6d6;\n  border: none;\n  height: 1px;\n}\n._2jjcVTEcGjbWGungKsQCpD {\n  height: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "yuSZdMGcH7mji3Jzt9xjP",
	"card-body": "jD-RN5wOOaD2atIMrNGW",
	"card-footer": "WSLQzNsO0-L-immdQKkaf",
	"card-header": "tWycaRNcjxZsnubCzO4HW",
	"card-divider": "_1-ij6lD0FLlP7Z6QspbLX-",
	"card-divider-bold": "_2jjcVTEcGjbWGungKsQCpD"
};