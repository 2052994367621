import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

const NotFound = () =>
  <div className='NotFound margin-xl'>
    <div className='grid grid-flex gutter grid-justify-center'>
      <div className='grid-item one-whole align-center'>
        <img src={require('./issue-tower.svg')} alt='Pisa Tower' />
      </div>
      <div className='grid-item one-whole align-center margin-bottom-m font-xl'>
        <Msg id='message.somethingWrong' defaultMessage='Unfortunately something went wrong' />
      </div>
      <div className='grid-item one-whole align-center'>
        <Msg id='message.pleaseCheckUrl' defaultMessage='Please check URL' />
      </div>
    </div>
  </div>

export default NotFound
