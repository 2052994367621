import { RATING_PROPERTY_UPDATE_SUCCESS, RATING_UPDATE_SUCCESS } from '../actions/rating'

export const initialState = {
  conditions: null,
  product: null,
  analysis: null,
  competence: null,
  accessibility: null,
  clarity: null,
  review: null,
  agreed: false,
  date: null,
  ratingAlreadyExists: false,
}

export const reducer = (rating = initialState, action) => {
  switch (action.type) {
  case RATING_UPDATE_SUCCESS:
    return action.rating
  case RATING_PROPERTY_UPDATE_SUCCESS:
    return { ...rating, [action.property]: action.value }
  default:
    return rating
  }
}
