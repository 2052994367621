import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { Button } from 'is24-corecss'

const MY_MORTGAGE_TRACKING = '?cmp_id=10-04802&cmp_name=finance_manager&cmp_position=finance_rating&cmp_creative=confirmation_page_teaser'
const MY_MORTGAGE_LINK = 'https://www.immobilienscout24.de/baufinanzierung/finanzierungsmanager/' + MY_MORTGAGE_TRACKING

const styles = {
  bulletList: {
    listStyle: 'inside',
  },
  check: {
    color: '#008289',
  },
}

const MyMortgageTeaser = () =>
  <div className='MyMortgageTeaser margin-l'>

    <div className='font-h4 align-center'>
      <Msg id='teaser.title' default='My Mortgage' />
    </div>

    <Msg id='teaser.subtext' default='Keep track of your property and the financing behind it.' />

    <div className='margin-m align-center'>
      <ul className='inline-block list-bullet margin-top-s' style={styles.bulletList}>
        <li>
          <Msg id='teaser.question1' default='How much of my home do you already own?' />
        </li>
        <li>
          <Msg
            id='teaser.question2'
            default='Until when do I have to keep on paying?'
            values={{ nbsp: <>&nbsp;</> }}
          />
        </li>
        <li>
          <Msg
            id='teaser.question3'
            default='How do value and interest rates develop?'
            values={{ nbsp: <>&nbsp;</> }}
          />
        </li>
      </ul>
    </div>

    <div className='align-center margin-top-l'>
      <a href={MY_MORTGAGE_LINK}>
        <Button>
          <Msg id='teaser.button' default='Sign up now' />
        </Button>
      </a>
    </div>

    <div className='grid grid-flex grid-justify-center margin-top-xl'>
      <div className='grid-item one-third font-xs align-center'>
        <span className='is24-icon-positive-check font-bold margin-right-xs' style={styles.check} />
        <Msg
          id='teaser.check1'
          default='100% free and not binding'
          values={{ br: <br /> }}
        />
      </div>

      <div className='grid-item one-third font-xs align-center'>
        <span className='is24-icon-positive-check font-bold margin-right-xs' style={styles.check} />
        <Msg
          id='teaser.check2'
          default='How do value and interest rates develop?'
          values={{ br: <br /> }}
        />
      </div>

      <div className='grid-item one-third font-xs align-center'>
        <span className='is24-icon-positive-check font-bold margin-right-xs' style={styles.check} />
        <Msg
          id='teaser.check3'
          default='How do value and interest rates develop?'
          values={{ br: <br /> }}
        />
      </div>
    </div>

  </div>

export default MyMortgageTeaser
