import { ratingUpdateSuccess } from '../actions/rating'
import { getLead } from '../services/lead/lead'
import { userUpdateSuccess } from '../actions/user'
import { agentUpdateSuccess } from '../actions/agent'

export const onExternalDataInit = () => async (dispatch, getState) => {
  const { user, rating, agent } = await getLead(getState().parties)
  dispatch(userUpdateSuccess(user))
  dispatch(agentUpdateSuccess(agent))
  if (rating.date) {
    dispatch(ratingUpdateSuccess(rating))
  }
}
