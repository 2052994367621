import React, { FC } from "react";

import CardStyles from "../Card.less";

type CardFooterProps = {
	/** Class property goes directly in card footer container div. */
	className?: string;
} & JSX.IntrinsicElements["div"];

/**
 *
 */
const CardFooter: FC<CardFooterProps> = ({
	children,
	className,
	...otherProps
}) => {
	const cardClassNames = [
		className,
		CardStyles["card-footer"]
	].filter(className => !!className).join(" ");

	return (
		<div className={cardClassNames} {...otherProps}>
			{children}
		</div>
	);
};

export { CardFooter };
