import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

import ConnectedCompanyName from '../agent/ConnectedCompanyName'
import RatingSection from './RatingSection'
import ReviewSection from './ReviewSection'
import { RatingShape } from '../../domain/rating/RatingShape'
import { RatingValidationShape } from '../../domain/rating/RatingValidationShape'
import { Button } from 'is24-corecss'
import TotalRating from './TotalRating'
import ConnectedAgent from '../agent/ConnectedAgent'
import { UserShape } from '../../domain/user/userShape'
import Box from '../common/display/Box'
import BannerRatingAlreadyExists from './BannerRatingAlreadyExists'

const RatingPage = ({ rating, ratingValidation, user, onChange, onPublishFeedback }) =>
  <div className='RatingPage margin-vertical-xl'>

    <div className='margin-bottom-xl'>
      <ConnectedCompanyName className='margin-bottom-xl' />
    </div>

    <Box className='margin-bottom-xl'>
      <ConnectedAgent />
    </Box>

    {rating.ratingAlreadyExists ? <BannerRatingAlreadyExists date={rating.date} /> : ''}

    <div className='Subtitle font-m font-semibold margin-top-xxl margin-bottom-m align-left'>
      <Msg id='rating.title' defaultMessage='Please rate the consultation' />
    </div>

    <Box>
      <RatingSection
        className='OfferQuality'
        section='offerQuality'
        subSection1='conditions'
        subSection2='product'
        rating1={rating.conditions}
        ratingValidation1={ratingValidation.conditions}
        rating2={rating.product}
        ratingValidation2={ratingValidation.product}
        onChange={onChange}
      />

      <RatingSection
        className='AdviceQuality'
        section='adviceQuality'
        subSection1='analysis'
        subSection2='competence'
        rating1={rating.analysis}
        ratingValidation1={ratingValidation.analysis}
        rating2={rating.competence}
        ratingValidation2={ratingValidation.competence}
        onChange={onChange}
      />

      <RatingSection
        className='ServiceQuality'
        section='serviceQuality'
        subSection1='clarity'
        subSection2='accessibility'
        rating1={rating.clarity}
        ratingValidation1={ratingValidation.clarity}
        rating2={rating.accessibility}
        ratingValidation2={ratingValidation.accessibility}
        onChange={onChange}
      />

      <ReviewSection
        review={rating.review}
        reviewValid={ratingValidation.review}
        user={user}
        agreed={rating.agreed}
        agreedValid={ratingValidation.agreed}
        onChange={onChange}
      />

      <div className='padding-vertical-l padding-horizontal-xl'>
        <div className='grid grid-flex grid-justify-space-between gutter-m'>
          <div className='grid-item font-lightgray'>
            <TotalRating rating={rating} />
          </div>
          <div className='grid-item palm-one-whole'>
            <Button
              className='send-review one-whole'
              appearance='primary'
              onClick={() => onPublishFeedback()}>
              <Msg id='rating.publishFeedback' defaultMessage='Publish Feedback' />
            </Button>
          </div>
        </div>
      </div>
    </Box>
  </div>

RatingPage.propTypes = {
  rating: RatingShape.isRequired,
  ratingValidation: RatingValidationShape.isRequired,
  user: UserShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onPublishFeedback: PropTypes.func.isRequired,
}

export default RatingPage
