const messages = {
  'en-US': {},
  'de-DE': {
    message: {
      somethingWrong: 'Da ist leider etwas schief gelaufen',
      pleaseCheckUrl: 'Bitte überprüfe die Schreibweise der Internetadresse (Groß-/Kleinschreibung) und/oder die Aktualität deines Lesezeichens.',
      pleaseCheckConnection: 'Leider kam es bei deiner Anfrage zu einem Fehler. Bitte versuche es erneut.',
    },
    qualification: {
      yes: 'Ja',
      no: 'Nein',
      back: 'Zurück',
    },
    consultationCheck: {
      title: 'Hat ein Beratungsgespräch stattgefunden?',
    },
    consultationWhyNot: {
      title: 'Weshalb kam es zu keinem Beratungsgespräch?',
      buttons: {
        noReachOut: 'Der Anbieter hat sich nicht gemeldet',
        missedCall: 'Ich konnte das Gespräch nicht annehmen',
        noCall: 'Ich wollte nicht mit dem Anbieter sprechen',
      },
    },
    noConsultationThankYou: {
      title: 'Vielen Dank für deine Rückmeldung!',
      text: 'Da kein Beratungsgespräch stattgefunden hat, kann die Beratungsleistung nicht bewertet werden.',
    },
    mortgageCheck: {
      title: 'Hast du mit dem Anbieter eine Finanzierung abgeschlossen?',
    },
    mortgageWhyNot: {
      title: 'Weshalb hast du keine Finanzierung abgeschlossen?',
      consultationIncomplete: 'Beratung noch nicht abgeschlossen',
      mortgageWithAnotherProvider: 'Ich habe mit einem anderen Anbieter finanziert',
      alternativeOffers: 'Ich suche nach alternativen Angeboten',
      mortgageNotNeeded: 'Ich möchte aktuell nicht finanzieren',
    },
    rating: {
      title: 'Bewerte die Beratung',
      info: {
        title: 'Das ist deine Bewertung vom {date}',
        text: 'Du kannst die Bewertung anpassen oder unverändert übernehmen.',
      },
      sections: {
        offerQuality: 'Angebotsqualität',
        conditions: 'Günstige Zinsen/ {br} Konditionen',
        product: 'Flexibilität des Finanzierungsangebots {br} (z.B Sondertilgung)',
        adviceQuality: 'Beratungsqualität',
        analysis: 'Analyse/Berücksichtigung der persönlichen Situation',
        competence: 'Kompetenz/{br}vertrauensvolles Auftreten',
        serviceQuality: 'Servicequalität',
        clarity: 'Übersichtlichkeit/{br}Verständlichkeit',
        accessibility: 'Erreichbarkeit und Bearbeitungsgeschwindigkeit',
      },
      codeOfConduct: {
        link: 'Ich akzeptiere weiterhin, dass ich meine Bewertung im Rahmen des<button>Bewertungs-Kodex</button>verfasse.',
        title: 'Bewertungs-Kodex',
        paragraph1: 'Bitte beachte beim Verfassen deiner Bewertung den Bewertungs-Kodex.',
        paragraph2: 'ImmobilienScout24 behält sich bei Verstößen gegen den Kodex das sofortige Löschen der betreffenden Bewertung vor.',
        paragraph3: 'Bewertungen werden respektvoll, ehrlich und sachlich verfasst und enthalten keine:',
        formalOffenses: 'Formalbeleidigungen',
        judgement: 'herabsetzenden Werturteile jeglicher Art',
        untrueStatements: 'unwahren herabsetzenden Tatsachenbehauptungen',
        abusiveCriticism: 'Schmähkritik',
        personalInsults: 'persönlichen Kränkungen und Herabwürdigungen',
        insultingStatements: 'verleumderischen oder beleidigenden Aussagen',
        discrediting: 'Diskreditierung von Wettbewerbern oder deren Produkte',
        sharingPersonalInfo: 'Personenbezogenen Angaben',
      },
      reviewTitle: 'Beschreibe deine persönlichen Erfahrungen',
      inputError: 'Fehler',
      agreed: 'Ich bin damit einverstanden, dass meine Bewertung auf ImmobilienScout24 <b>anonym veröffentlicht</b> wird. Mein Name wird in verkürzter Form neben der Bewertung angezeigt ',
      total: 'Gesamtbewertung:',
      pleaseRate: 'Hinweis: Bitte bewerte alle Kriterien {br} und trage deine Erfahrungen ein.',
      stars: 'Sterne',
      publishFeedback: 'Bewertung veröffentlichen',
      thankYou: {
        title: 'Vielen Dank für deine Bewertung',
        text: 'Deine Bewertung wird in den nächsten 15 Minuten sichtbar.',
      },
      returningUser: {
        alreadyReviewedPt1: 'Du hast den Anbieter bereits am',
        alreadyReviewedPt2: 'bewertet.',
      },
      invalid: {
        message: {
          title: 'Hinweis',
          content: 'Bitte fülle alle Felder aus.',
        },
      },
    },
    salutation: {
      MR: 'Herr',
      MRS: 'Frau',
    },
    agent: {
      title: 'Deine Meinung zählt, bitte bewerte',
      link: 'Zum Anbieterprofil',
      responseRate: {
        title: 'Rückmeldungsquote',
        text: 'Aufgrund Ihrer Angaben errechnen wir eine Rückmeldungsquote des Anbieters. Zusätzlich werden wir den Anbieter informieren um die Beratungsqualität zu steigern.',
        rate: 'Aktuelle Rückmeldungsquote',
      },
    },
    teaser: {
      title: 'Nutze jetzt den FinanzierungsManager',
      subtext: 'und behalte so den Überblick zu deiner Immobilie und der Finanzierung dahinter.',
      question1: 'Wie viele Eigenheime besitzt du schon?',
      question2: 'Bis wann musst du noch bezahlen? {nbsp}',
      question3: 'Wie entwickeln sich Wert und Zinsen? {nbsp}',
      button: 'Jetzt einrichten',
      check1: '100 % kostenlos & {br} unverbindlich',
      check2: 'Aktueller {br} Tilgungsplan',
      check3: 'Anschlussfinanzierung {br} sichern',
    },
  },
}

export default messages
