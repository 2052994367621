export const TrackingEvents = {
  qualification: {
    CONSULTATION_CHECK_PAGE: { page: 'consultation' },
    CONSULTATION_WHY_NOT_PAGE: { page: 'noconsultation' },
    CONSULTATION_THANK_YOU_PAGE: { page: 'confirm_noconsultation' },
    MORTGAGE_CHECK_PAGE: { page: 'contract' },
    MORTGAGE_WHY_NOT_PAGE: { page: 'nocontract' },
  },
  rating: {
    RATING_PAGE: { page: 'ratingdetails' },
    RATING_THANK_YOU_PAGE: { page: 'confirm' },
    RETURNING_USER_PAGE: { page: 'confirm_returninguser' },
  },
  buildInfo: {
    BUILD_INFO: { page: 'build_info' },
  },
  error: {
    ERROR: { page: 'error' },
  },
  notFound: {
    NOT_FOUND: { page: 'not_found' },
  },
}
