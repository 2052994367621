export const FORMAL_OFFENSES = 'FORMAL_OFFENSES'
export const JUDGEMENT = 'JUDGEMENT'
export const UNTRUE_STATEMENTS = 'UNTRUE_STATEMENTS'
export const ABUSIVE_CRITICISM = 'ABUSIVE_CRITICISM'
export const PERSONAL_INSULTS = 'PERSONAL_INSULTS'
export const INSULTING_STATEMENTS = 'INSULTING_STATEMENTS'
export const DISCREDITING = 'DISCREDITING'
export const SHARING_PERSONAL_INFO = 'SHARING_PERSONAL_INFO'

export const CODE_OF_CONDUCT_OFFENCES = [ FORMAL_OFFENSES, JUDGEMENT, UNTRUE_STATEMENTS, ABUSIVE_CRITICISM, PERSONAL_INSULTS, INSULTING_STATEMENTS, DISCREDITING, SHARING_PERSONAL_INFO ]
