import React, { FC } from "react";

import CardStyles from "./Card.less";

type CardProps = {
	/** Class property goes directly in tooltip's root div. */
	className?: string;
	/** Class property goes directly in tooltip's root div. */
	linkAddress?: string;
} & JSX.IntrinsicElements["div"];

/**
 * Cards consist of a Card tag containing at least one of the following: Card Header, Card Body and Card Footer
 */
const Card: FC<CardProps> = ({
	children,
	linkAddress,
	className,
	...otherProps
}) => {
	const cardClassNames = [
		className,
		CardStyles["card"]
	].filter(className => !!className).join(" ");

	return (
		<section className={cardClassNames} {...otherProps}>
			<a href={linkAddress}>
				{children}
			</a>
		</section>
	);
};

export { Card };
