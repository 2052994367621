import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Message from './Message'
import * as actions from '../../../thunks/message'

export default connect(
  state => ({ message: state.message }),
  dispatch => bindActionCreators(actions, dispatch),
)(({ message, ...otherProps }) => message && <Message {...otherProps} message={message} />)
