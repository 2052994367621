import React, { Component, RefObject } from "react";
import { AccordionIcon } from "../AccordionIcon/AccordionIcon";

import AccordionStyles from "../Accordion.less";

type focusedElement = number | undefined | null;

type AccordionItemProps = {
	index?: number;
	currentlyFocused?: focusedElement;
	title: React.ReactNode;
	defaultExpanded?: boolean;
	id?: string;
	children: React.ReactNode;
	onToggle?: (event: number) => void;
	onNavigation?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	panelClassName?: string;
	setFocus?: (e) => void;
	titleClassName?: string;
}

type AccordionItemState = {
	expanded: boolean;
}

class AccordionItem extends Component<AccordionItemProps, AccordionItemState> {
	private labelRef: RefObject<HTMLDivElement>;
	private panelRef: React.RefObject<HTMLDivElement>;
	private contentRef: React.RefObject<HTMLDivElement>;

	public constructor(props) {
		super(props);

		this.labelRef = React.createRef();

		this.state = {
			expanded: this.props.defaultExpanded || false
		};
	}

	public componentDidUpdate(prevProps) {
		if (prevProps.currentlyFocused !== this.props.currentlyFocused) {
			if (this.props.currentlyFocused === this.props.index) {
				// For accessibility reasons, manually set the focus to the currentlyFocused index.
				// This way, the screen reader will announce the title of the selected accordion header on keypresses.
				window.requestAnimationFrame(() => {
					this.labelRef.current.focus();
				});
			}
		}
	}

	public onKeyDown = (nav: React.KeyboardEvent<HTMLDivElement>) => {
		const { key } = nav;
		const { index, onNavigation } = this.props;

		switch (key) {
			case " ":
			case "Enter":
				// For accessibility reasons, prevent the default action of the keypress.
				nav.preventDefault();
				this.onToggle(index);
				break;
			case "Up":
			case "Down":
			case "ArrowUp":
			case "ArrowDown":
			case "Home":
			case "End":
				// In this block we don't have to worry about these keys since they will be handled by the parent component function onNavigation
				nav.preventDefault();
				onNavigation(nav);
				break;
			default:
		}
	}

	public onToggle = (index: number) => {
		const { expanded } = this.state;
		let newExpanded = expanded;

		newExpanded = !newExpanded;
		this.setState({ expanded: newExpanded });
		this.props.onToggle && this.props.onToggle(index);
	}

	public render() {
		const { children, id, index, panelClassName, setFocus, title, titleClassName } = this.props;
		const sectionId = `is24-accordion-${id}-${index}-section`;
		const labelId = `is24-accordion-${id}-${index}-label`;
		const isExpanded = this.state.expanded;

		const accordionItemClassName = [
			AccordionStyles["accordion-header"],
			isExpanded ? AccordionStyles["expanded"] : "",
			titleClassName
		].filter(className => !!className).join(" ");

		const accordionPanelClassName = [
			AccordionStyles["accordion-panel"],
			isExpanded ? AccordionStyles["expanded"] : "",
			panelClassName
		].filter(className => !!className).join(" ");

		return (
			<>
				<div
					role="button"
					className={accordionItemClassName}
					aria-expanded={isExpanded}
					aria-controls={sectionId}
					id={labelId}
					tabIndex={0}
					ref={this.labelRef}
					onClick={() => this.onToggle(index)}
					onKeyDown={(e) => this.onKeyDown(e)}
					onFocus={() => {
						setFocus(index);
					}}
					onBlur={() => {
						setFocus(null);
					}}
				>
					<span>{title}</span>
					<AccordionIcon className={isExpanded ? AccordionStyles["expanded"] : ""} aria-hidden={true} />
				</div>
				<div
					className={accordionPanelClassName}
					role="region"
					aria-labelledby={labelId}
					id={sectionId}
				>
					<div ref={this.contentRef}>{children}</div>
				</div>
			</>
		);
	}
}

export { AccordionItem };
