import { push, goBack, replace } from 'connected-react-router'

const addContext = (location, getState) => {
  let { parties: { leadId, ratingtoken }, router: { location: { search } } } = getState()
  return location.replace(':leadId', leadId).replace(':ratingtoken', ratingtoken).concat(search)
}

export const onReplace = location => (dispatch, getState) => {
  dispatch(replace(addContext(location, getState)))
}

export const onPush = location => (dispatch, getState) => {
  dispatch(push(addContext(location, getState)))
  window.scrollTo(0, 0)
}

export const onBack = () => dispatch => {
  dispatch(goBack())
}
