import React, { FC } from "react";

import TooltipStyles from "./Tooltip.less";

type TooltipProps = {
	/** Class property goes directly in tooltip's root div. */
	className?: string;
	/** The position of tooltip itself in relation to target element. */
	bodyPosition?: "top" | "right" | "bottom" | "left";
	/** The position of tooltip's arrow along the edge. (!)Only for bottom and top positioning! */
	arrowPosition?: "left" | "center" | "right";
} & JSX.IntrinsicElements["div"];

/**
 * Tooltips can be used to provide more or specific information and can contain everything from strings to whole forms with CTAs.
 */
const Tooltip: FC<TooltipProps> = ({
	children,
	className,
	arrowPosition,
	bodyPosition,
	...otherProps
}) => {
	const tooltipClassName = [
		"CosmaTooltip",
		className,
		TooltipStyles["tooltip"],
		arrowPosition && TooltipStyles[`arrow-${arrowPosition}`],
		bodyPosition && TooltipStyles[`positioning-${bodyPosition}`],
	].filter(className => !!className).join(" ");

	return (
		<div className={tooltipClassName} {...otherProps}>
			{children}
		</div>
	);
};

Tooltip.defaultProps = {
	arrowPosition: "center",
	bodyPosition: "bottom",
};

export { Tooltip };
