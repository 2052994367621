import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

import { UserShape } from '../../domain/user/userShape'

const Disclaimer = ({ user, onCodeOfConductOpen }) =>
  <>
    <Msg
      id='rating.agreed'
      values={{ b: value => <b>{value}</b> }}
    />
    <span className='font-bold'>
      <span className='UserName'>{user.name ? `(${user.name})` : '(Beispiel: Frau Anna M.)'}</span>
    </span>
    <p className='openCodeOfConduct' onClick={e => { e.preventDefault(); onCodeOfConductOpen() }}>
      <Msg
        id='rating.codeOfConduct.link'
        defaultMessage='I accept that my review will be written in accordance to the review code of conduct'
        values={{ button: link => <span className='margin-horizontal-xs link-text'>{link}</span> }}
      />
    </p>
  </>

Disclaimer.propTypes = {
  user: UserShape.isRequired,
  onCodeOfConductOpen: PropTypes.func.isRequired,
}

export default Disclaimer
