import React from 'react'
import PropTypes from 'prop-types'

export const styles = {
  root: {
    minHeight: 100,
  },
}

const TextArea = ({ value, valid, onFocus = () => {}, onChange = () => {}, onBlur = () => {}, ...otherProps }) =>
  <textarea
    {...otherProps}
    value={value}
    className={'TextArea textarea' + (valid === false ? ' error' : '')}
    style={styles.root}
    onFocus={({ target: { value } }) => onFocus(value)}
    onChange={({ target: { value } }) => onChange(value)}
    onBlur={({ target: { value } }) => onBlur(value)}
  />

TextArea.propTypes = {
  value: PropTypes.string,
  valid: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextArea
