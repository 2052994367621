import React, { createRef, Component, RefObject, ReactNode } from "react";

import stepperStyles from "./Stepper.less";
import fontStyles from "../../Fonts/Fonts.less";

type StepperProps = {
	id: string;
	errorMessage?: string;
	defaultValue?: string;
	label: ReactNode;
	helperText?: string;
	valid?: boolean;
	inputRef?: RefObject<HTMLInputElement>;
	/** How much a number gets increased or decreased with each click */
	step?: number;
	/** The minimum value a user can set.
	 *
	 * **Caution**: Using this alongside `step` has an influence on the values that can be set. For example, setting a min of 2 and a step of 3 requires all values to be a result of `min + step * n = 2 + 3 * n`. */
	min?: number;
	max?: number;
} & Omit<JSX.IntrinsicElements["input"], "children" | "type">

type StepperState = {
	ref: RefObject<HTMLInputElement>;
}

class Stepper extends Component<StepperProps, StepperState> {
	public static defaultProps = {
		min: 0,
		max: 100,
		step: 1,
		defaultValue: 1
	}

	public constructor(props) {
		super(props);

		this.state = {
			ref: props.inputRef ? props.inputRef : createRef<HTMLInputElement>()
		};

		this.stepWithIE11Compatibility = this.stepWithIE11Compatibility.bind(this);
	}

	/** Special treatment for IE11 because it does support stepUp and stepDown for type="number". However, these are supported for type="range". Knowing this, we can cheat our way towards having native stepUp and stepDown functionality.
	 */
	private stepWithIE11Compatibility = (stepFunctionName: string) => {
		const numberField = this.state.ref.current;

		numberField.type = "range";
		try {
			numberField[stepFunctionName]();
		} finally {
			// IE11 discards the value when changing back to number so we have to reset it manually.
			const newValue = numberField.value;

			numberField.type = "number";
			numberField.value = newValue;
		}
	}
	
	public render() {
		const {
			errorMessage,
			label,
			helperText,
			className,
			...props
		} = this.props;

		let valid = this.props.valid;

		if (valid === undefined) {
			valid = true;
		}

		const containerClassName = `${stepperStyles["input-text-container"]} : ""}`;
		let textFieldClassName = `${stepperStyles["input-text"]} ${className}`;

		if (!valid) {
			textFieldClassName += ` ${stepperStyles["error"]}`;
		}

		return (
			<div className={containerClassName}>
				{label && <label className={stepperStyles["input-label"]} htmlFor={props.id}>{label}</label>}
				<div className={stepperStyles["stepper-input-container"]}>
					<button
						type="button"
						aria-label="Minus"
						onClick={() => this.stepWithIE11Compatibility("stepDown")}
						className={stepperStyles["stepper-decrement-button"]}>-</button>
					<input
						type="number"
						ref={this.state.ref}
						className={textFieldClassName}
						{...props}
					/>
					<button
						type="button"
						aria-label="Plus"
						onClick={() => this.stepWithIE11Compatibility("stepUp")}
						className={stepperStyles["stepper-increment-button"]}>+</button>
				</div>
				<span className={stepperStyles["input-label-helper-text"]}>{helperText}</span>
				{errorMessage && !valid && <span className={`${stepperStyles["input-label-helper-text"]} ${fontStyles["font-error"]}`}>{errorMessage}</span>}
			</div>
		);
	}
}

export { Stepper };
