import {
  CONSULTATION_CHECK,
  CONSULTATION_WHY_NOT,
  CONSULTATION_THANK_YOU,
  MORTGAGE_CHECK,
  MORTGAGE_WHY_NOT,
  RATING,
  RATING_THANK_YOU,
  RETURNING_USER,
  ERROR,
  BUILD_INFO, ROOT,
} from '../domain/routes'
import { report } from '../tracking/tracking'
import { TrackingEvents } from '../tracking/trackingEvents'

const PATH_TO_EVENT = {
  [BUILD_INFO]: TrackingEvents.buildInfo.BUILD_INFO,
  [ERROR]: TrackingEvents.error.ERROR,
  [RETURNING_USER]: TrackingEvents.rating.RETURNING_USER_PAGE,
  [RATING_THANK_YOU]: TrackingEvents.rating.RATING_THANK_YOU_PAGE,
  [RATING]: TrackingEvents.rating.RATING_PAGE,
  [MORTGAGE_WHY_NOT]: TrackingEvents.qualification.MORTGAGE_WHY_NOT_PAGE,
  [MORTGAGE_CHECK]: TrackingEvents.qualification.MORTGAGE_CHECK_PAGE,
  [CONSULTATION_THANK_YOU]: TrackingEvents.qualification.CONSULTATION_THANK_YOU_PAGE,
  [CONSULTATION_WHY_NOT]: TrackingEvents.qualification.CONSULTATION_WHY_NOT_PAGE,
  [CONSULTATION_CHECK]: TrackingEvents.qualification.CONSULTATION_CHECK_PAGE,
  [ROOT]: TrackingEvents.qualification.CONSULTATION_CHECK_PAGE,
}

export const onTrackPageVisit = () => (dispatch, getState) => {
  const pathname = getState().router.location.pathname
  const path = Object.keys(PATH_TO_EVENT).find(path => pathname.endsWith(path.split('/').pop()))
  report(PATH_TO_EVENT[path] || TrackingEvents.notFound.NOT_FOUND)
}
