import { sendRating } from '../services/rating/rating'
import { ratingInvalid, ratingSendFailure, ratingPropertyUpdate } from '../actions/rating'
import { onPush } from './routing'
import { RATING_THANK_YOU } from '../domain/routes'
import { isValidRating } from '../domain/rating/ratingValidation'
import { onMessageShow } from './message'
import { ERROR } from '../domain/message/messageTypes'
import moment from 'moment'
import { userUpdateSuccess } from '../actions/user'
import { agentUpdateSuccess } from '../actions/agent'

const RATING_INVALID_MESSAGE = {
  type: ERROR,
  titleId: 'rating.invalid.message.title',
  contentId: 'rating.invalid.message.content',
}

export const onChange = ({ property, value }) => dispatch => {
  dispatch(ratingPropertyUpdate(property, value))
}

export const onPublishFeedback = () => async (dispatch, getState) => {
  try {
    if (isValidRating(getState().rating)) {
      let { user, agent } = await sendRating(getState().parties, getState().rating, getState().qualification)
      dispatch(onPush(RATING_THANK_YOU))
      await dispatch(userUpdateSuccess(user))
      await dispatch(agentUpdateSuccess({ ...getState().agent, responseRate: agent.responseRate }))
      await dispatch(ratingPropertyUpdate('date', moment().toISOString()))
    }
    else {
      dispatch(ratingInvalid(getState().rating))
      dispatch(onMessageShow(RATING_INVALID_MESSAGE))
    }
  } catch (error) {
    dispatch(ratingSendFailure(error))
  }
}

