import React from 'react'
import PropTypes from 'prop-types'

export const backgroundStroke = '#cccccc'
export const foregroundStroke = '#008289'

export const radius = 13
export const strokeWidth = 3

const size = (radius + strokeWidth) * 2
const cx = radius + strokeWidth
const cy = radius + strokeWidth

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

const calculateArc = value => {
  const x = size / 2
  const y = (size - radius * 2) / 2
  const xAxisRotation = 0
  const largeArcFlag = value > 0.5 ? 1 : 0
  const sweepFlag = 1
  const angleInDegrees = value * 360
  const { x: dx, y: dy } = polarToCartesian(0, radius, radius, angleInDegrees)

  return `M${x},${y} a ${radius} ${radius} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${dx} ${dy}`
}

const Ring = ({ value }) =>
  <span className='Ring'>
    <svg height={size} width={size}>
      <circle cx={cx} cy={cy} r={radius} stroke={backgroundStroke} strokeWidth={strokeWidth} fill='none' />
      {value === 1.0 ?
        <circle cx={cx} cy={cy} r={radius} stroke={foregroundStroke} strokeWidth={strokeWidth} fill='none' />
        :
        <path d={calculateArc(value)} stroke={foregroundStroke} strokeWidth={strokeWidth} fill='none' />
      }
    </svg>
  </span>

Ring.propTypes = {
  value: PropTypes.number.isRequired,
}

export default Ring
