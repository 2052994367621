import moment from 'moment'
import { toApi as toApiConsultation } from './consultationWhyNot'
import { toApi as toApiContract } from './mortgageWhyNot'

export const ratingToApp = ({
  roundedOverallScore,
  message,
  created,
  offerRatingRates,
  consultingRatingCompetence,
  serviceRatingClarity,
  serviceRatingAvailability,
  offerRatingFlexibility,
  consultingRatingIndividualization,
  isContractCompleted,
}) => ({
  conditions: offerRatingRates || null,
  product: offerRatingFlexibility || null,
  analysis: consultingRatingIndividualization || null,
  competence: consultingRatingCompetence || null,
  clarity: serviceRatingClarity || null,
  accessibility: serviceRatingAvailability || null,
  review: message || null,
  roundedOverallScore: roundedOverallScore || null,
  date: created ? moment(created).toISOString() : null,
  isContractCompleted: isContractCompleted || false,
  ratingAlreadyExists: !!created,
})

export const ratingToApi = ({
  consultationDidHappen,
  mortgageObtained,
  consultationWhyNot,
  mortgageWhyNot,
  conditions,
  product,
  analysis,
  competence,
  clarity,
  accessibility,
  review,
}) => ({
  isConsulted: consultationDidHappen,
  isContractCompleted: mortgageObtained === null ? undefined : mortgageObtained,
  noConsultingReason: toApiConsultation(consultationWhyNot),
  noContractReason: toApiContract(mortgageWhyNot),
  offerRatingRates: conditions || undefined,
  offerRatingFlexibility: product || undefined,
  consultingRatingIndividualization: analysis || undefined,
  consultingRatingCompetence: competence || undefined,
  serviceRatingClarity: clarity || undefined,
  serviceRatingAvailability: accessibility || undefined,
  message: review || undefined,
})
