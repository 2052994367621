import React, { Component, ReactNode } from "react";
import { uuidv4 } from "../helpers";
import TabGroupStyles from "./Tabs.less";


type TabsProps = {
	defaultSelected?: number;
	children: ReactNode;
	onClick?: Function;
}

/** Relates to: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html */
class TabsContainer extends Component<TabsProps, {}> {
	private tabList: HTMLElement;
	private tabs: NodeListOf<HTMLElement>;
	private tabPanels: NodeListOf<HTMLElement>;
	private tabFocus = 0;
	private ref = React.createRef<HTMLDivElement>();

	public componentDidMount(): void {
		const getElements = role => { return this.ref.current.querySelectorAll<HTMLElement>(`[role="${role}"]`); };

		this.tabList = getElements("tablist")[0];
		this.tabs = getElements("tab");
		this.tabPanels = getElements("tabpanel");

		const { defaultSelected } = { ...this.props };

		if (typeof defaultSelected === "number") {
			this.changeTab(defaultSelected);
		}

		if (this.tabPanels) {
			this.linkTabsWithPanels();
		}

		this.addEventListeners();
	}

	public componentWillUnmount() {
		this.removeEventListeners();
	}

	private removeEventListeners() {
		this.tabs.forEach((tab) => {
			tab.removeEventListener("click", this.handleTabClick);
		});

		this.tabList.removeEventListener("keydown", this.handleTabListKey);
	}

	private addEventListeners() {
		this.tabs.forEach((tab) => {
			tab.addEventListener("click", this.handleTabClick);
		});

		this.tabList.addEventListener("keydown", this.handleTabListKey);
	}

	private changeTab(tabIndex) {
		this.tabs.forEach((tab, index) => {
			const isTab = tabIndex === index;

			tab.setAttribute("aria-selected", isTab.toString());

			if (isTab) { this.changePanel(index); }
		});
	}

	private changePanel(panelIndex) {
		this.tabPanels.forEach((panel, index) => {
			if (panelIndex === index) {
				panel.removeAttribute("hidden");
			} else {
				panel.setAttribute("hidden", "");
			}
		});
	}

	private handleTabClick = (event: MouseEvent) => {
		for (let i  = 0; i < this.tabs.length; i++) {
			if (event.target === this.tabs[i]) {
				this.changeTab(i);
				this.props.onClick && this.props.onClick(i);
				break;
			}
		}
	};

	/**
	 *  The tabindex applies only to the active tab and his tabpanel to tab from the active button to the content
	 *  Other tabs are not tabbable (-1) and are reachable by the arrow keys
	 */
	private handleTabListKey = (event: KeyboardEvent) => {
		if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
			this.tabs[this.tabFocus].setAttribute("tabindex", "-1");
			if (event.key === "ArrowRight") {
				this.tabFocus++;
				if (this.tabFocus >= this.tabs.length) {
					this.tabFocus = 0;
				}
			} else if (event.key === "ArrowLeft") {
				this.tabFocus--;
				if (this.tabFocus < 0) {
					this.tabFocus = this.tabs.length - 1;
				}
			}

			this.tabs[this.tabFocus].setAttribute("tabindex", "0");
			this.tabs[this.tabFocus].focus();
		}
	};

	private linkTabsWithPanels() {
		this.tabs.forEach((tab, index) => {
			const tabId = uuidv4();

			tab.setAttribute("id", tabId);

			const tabPanel = this.tabPanels[index];

			if (tabPanel) {
				tab.setAttribute("tabindex", index === 0 ? "0" : "-1");

				const tabPanelId = tabPanel.getAttribute("id") || uuidv4();

				tab.setAttribute("aria-controls", tabPanelId);

				tabPanel.setAttribute("id", tabPanelId);
				tabPanel.setAttribute("aria-labelledby", tabId);
			}
		});
	}

	public render() {
		return (
			<div ref={this.ref} className={TabGroupStyles["tabs-container"]}>
				{this.props.children}
			</div>
		);
	}
}

export { TabsContainer };
