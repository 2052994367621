import { post, put } from '../common/request'
import { ratingToApi } from './converters/rating'
import { leadToApp } from '../lead/converters/lead'
import { API_ENDPOINT } from '../../config'

const getOptions = ({ leadId, ratingtoken }, rating, qualification) => ({
  url: `${API_ENDPOINT}/rating/${leadId}?ratingtoken=${ratingtoken}`,
  body: ratingToApi({ ...rating, ...qualification }),
})

export const sendRating = (parties, rating, qualification) => {
  if (rating.ratingAlreadyExists) {
    return updateRating(parties, rating, qualification)
  } else {
    return createRating(parties, rating, qualification)
  }
}

export const createRating = async ({ leadId, ratingtoken }, rating, qualification) => leadToApp(await post(getOptions({ leadId, ratingtoken }, rating, qualification)))
export const updateRating = async ({ leadId, ratingtoken }, rating, qualification) => leadToApp(await put(getOptions({ leadId, ratingtoken }, rating, qualification)))
