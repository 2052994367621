export const isValid = value => !!value

export const isValidReview = value => isValid(value) && value.length <= 4000

export const isValidRating = ({ conditions, product, analysis, competence, accessibility, clarity, review, agreed }) =>
  isValid(conditions) &&
  isValid(product) &&
  isValid(analysis) &&
  isValid(competence) &&
  isValid(accessibility) &&
  isValid(clarity) &&
  isValidReview(review) &&
  isValid(agreed)
