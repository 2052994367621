import { USER_UPDATE_SUCCESS } from '../actions/user'

export const initialState = {
  name: null,
}

export const reducer = (user = initialState, action) => {
  switch (action.type) {
  case USER_UPDATE_SUCCESS:
    return action.user
  default:
    return user
  }
}
