import React, { FC, ReactNode } from "react";
import TabGroupStyles from "./Tabs.less";

type TabPanelProps = {
	children: ReactNode;
}

const TabPanel: FC<TabPanelProps> = ({
	children,
}) => (
	<div role="tabpanel"
		tabIndex={0}
		hidden
		className={TabGroupStyles["tab-panel"]}>
		{children}
	</div>
);

export { TabPanel };
