import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  ROOT,
  CONSULTATION_CHECK,
  CONSULTATION_WHY_NOT,
  CONSULTATION_THANK_YOU,
  MORTGAGE_CHECK,
  MORTGAGE_WHY_NOT,
  RATING,
  RATING_THANK_YOU,
  RETURNING_USER,
  ERROR,
} from '../domain/routes'
import ConnectedConsultationCheck from './qualification/consultationCheck/ConnectedConsultationCheck'
import ConnectedConsultationWhyNot from './qualification/consultationWhyNot/ConnectedConsultationWhyNot'
import ConnectedMortgageCheck from './qualification/mortgageCheck/ConnectedMortgageCheck'
import ConnectedMortgageWhyNot from './qualification/mortgageWhyNot/ConnectedMortgageWhyNot'
import ConnectedRatingPage from './rating/ConnectedRatingPage'
import RatingThankYou from './rating/RatingThankYou'
import NoConsultationThankYou from './qualification/noConsultationThankYou/NoConsultationThankYou'
import ConnectedReturningUser from './rating/ConnectedReturningUser'
import Error from './common/issues/Error'
import NotFound from './common/issues/NotFound'

const Routes = () =>
  <Switch>
    <Redirect exact from={ROOT} to={CONSULTATION_CHECK} />
    <Route path={CONSULTATION_CHECK} component={ConnectedConsultationCheck} />
    <Route path={CONSULTATION_WHY_NOT} component={ConnectedConsultationWhyNot} />
    <Route path={CONSULTATION_THANK_YOU} component={NoConsultationThankYou} />
    <Route path={MORTGAGE_CHECK} component={ConnectedMortgageCheck} />
    <Route path={MORTGAGE_WHY_NOT} component={ConnectedMortgageWhyNot} />
    <Route path={RATING} component={ConnectedRatingPage} />
    <Route path={RATING_THANK_YOU} component={RatingThankYou} />
    <Route path={RETURNING_USER} component={ConnectedReturningUser} />
    <Route exact path={ERROR} component={Error} />
    <Route path='*' component={NotFound} />
  </Switch>

export default Routes
