import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

const CompanyName = ({ name }) =>
  <div className='Title font-xxl palm-font-xl align-left'>
    <Msg id='agent.title' defaultMessage='Rate financing provider' />
    <p className='CompanyName palm-hide font-bold'>
      {name}
    </p>
    <span className='CompanyName desk-hide lap-hide font-bold'>
      &nbsp;{name}
    </span>
  </div>

CompanyName.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CompanyName
