import React from 'react'
import PropTypes from 'prop-types'

export const EMPTY = 'is24-icon-rating-state-1'
export const HALF = 'is24-icon-rating-state-2'
export const FULL = 'is24-icon-rating-state-3'

const styles = {
  color: '#F2CA26',
}

const Star = ({ appearance }) =>
  <span
    className={`Star inline-block font-xl ${appearance}`}
    style={appearance === HALF ? { transform: 'scaleX(-1)', ...styles } : styles}
  />

Star.propTypes = {
  appearance: PropTypes.oneOf([ EMPTY, HALF, FULL ]).isRequired,
}

export default Star
