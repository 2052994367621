import React, { FC } from "react";
import { FontsProps } from "./FontsProps";

import FontStyles from "./Fonts.less";

const Font: FC<FontsProps> = (props) => {
	const Component = props.tag;
	const fontClassNames = [
		props.alignment && FontStyles[`font-${props.alignment}`],
		props.color && FontStyles[`font-${props.color}`],
		props.breakWords && `${FontStyles["font-break-all"]}`,
		props.ellipsis && `${FontStyles["font-ellipsis"]}`,
		props.fontSyle && FontStyles[`font-${props.fontSyle}`],
		props.highlight && `${FontStyles["font-highlight"]}`,
		props.hint && `${FontStyles["font-hint"]}`,
		props.lineHeight && FontStyles[`font-line-${props.lineHeight}`],
		props.noWrap && `${FontStyles["font-nowrap"]}`,
		props.tabular && `${FontStyles["font-tabular"]}`,
		props.transform && FontStyles[`font-${props.transform}`],
		props.weight && FontStyles[`font-${props.weight}`],
		props.className
	].filter(className => !!className).join(" ");

	return (
		<Component className={fontClassNames} style={props.style}>
			{props.children}
		</Component>
	);
};

Font.defaultProps = {
	tag: "p",
	className: "",
	weight: "normal",
	breakWords: false,
	ellipsis: false,
	highlight: false,
	hint: false,
	noWrap: false,
	tabular: false
};

export { Font };
